export const workTags = [
  {
      id: 1,
      tagName: "MUSIC PROMOS"
  },
  {
      id: 2,
      tagName: "COMMERCIAL"
  },
  {
      id: 3,
      tagName: "SHORT FORM"
  },
  {
      id: 4,
      tagName: "OTHER"
  }
]

export const gridItems = [
    {
      title: "Hash n Blem",
      vimeo: "https://vimeo.com/923423171/fca2072254?share=copy",
      artist: "El Grande Toto ft Unknown T",
      runTime: [3, 3],
      equipment: "Arri 416, Bolex EL + Canon 6-84mm & Optex 5.5mm",
      date: "Autumn '23",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Don Prod',
      producer: 'Don Prod',
      grade: "Jared Rosenthall",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "Kodak Vision3 16mm 7213, 7219",
      thumbnails: {
        placeholder: "../media/main/hashnblem/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/hashnblem/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/hashnblem/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/hashnblem/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/hashnblem/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/hashnblem/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1, 4],
      stills: [
        {
          backgroundImage: "../media/main/hashnblem/stills/mobile/still1.webp",
          placeholder: "../media/main/hashnblem/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/hashnblem/stills/mobile/still2.webp",
          placeholder: "../media/main/hashnblem/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/hashnblem/stills/mobile/still3.webp",
          placeholder: "../media/main/hashnblem/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/hashnblem/stills/mobile/still4.webp",
          placeholder: "../media/main/hashnblem/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/hashnblem/stills/mobile/still5.webp",
          placeholder: "../media/main/hashnblem/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/hashnblem/stills/mobile/still6.webp",
          placeholder: "../media/main/hashnblem/stills/placeholder/still6.webp"
        }
      ]
    },
    {
      title: "NSG",
      artist: "Nella Rose",
      vimeo: "https://vimeo.com/900539172/7f517f6a11?share=copy",
      runTime: [4, 20],
      equipment: "Alexa Mini w/ Cooke S4's & Angenieux Zooms",
      date: "Summer '23",
      director: "DON PROD",
      dop: 'MICHAEL R JOHNSON',
      editor: 'Harry Tatem',
      producer: 'Adam John Watts',
      grade: "Ricardo Spap",
      shotOn: "Arri Alexa",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      thumbnails: {
        placeholder: "../media/main/nsg/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/nsg/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/nsg/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/nsg/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/nsg/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/nsg/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1],
      stills: [
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still1.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still2.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still3.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still4.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still5.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still6.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still6.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still7.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still7.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still8.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still8.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still9.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still9.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still10.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still10.webp"
        },
        {
          backgroundImage: "../media/main/nsg/stills/mobile/still11.webp",
          placeholder: "../media/main/nsg/stills/placeholder/still11.webp"
        }
      ]
    },
    {
      title: "Dat Way",
      vimeo: "https://vimeo.com/922160608/0a0a90c3a1?share=copy",
      artist: "Krept & Konan",
      runTime: [4, 11],
      equipment: "Arri Sr3 + Canon 8-64mm & Zeiss SS MK3",
      date: "Summer '23",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Don Prod',
      producer: 'Don Prod',
      grade: "Michael R. Johnson",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: 'Kodak Vision3 16mm 7213, 7219',
      thumbnails: {
        placeholder: "../media/main/datway/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/datway/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/datway/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/datway/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/datway/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/datway/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1, 2],
      stills: [
        {
          backgroundImage: "../media/main/datway/stills/mobile/still1.webp",
          placeholder: "../media/main/datway/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/datway/stills/mobile/still2.webp",
          placeholder: "../media/main/datway/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/datway/stills/mobile/still3.webp",
          placeholder: "../media/main/datway/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/datway/stills/mobile/still4.webp",
          placeholder: "../media/main/datway/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/datway/stills/mobile/still5.webp",
          placeholder: "../media/main/datway/stills/placeholder/still5.webp"
        }
      ]
    },
    {
      title: "Tip Toes",
      artist: "Clavish ft. Aitch",
      vimeo: "https://vimeo.com/900575927/a9d269b89f?share=copy",
      runTime: [2, 4],
      equipment: "Arri 416",
      date: "Summer 2023",
      director: "DON PROD",
      dop: 'MICHAEL R JOHNSON',
      editor: 'Jack Millward',
      producer: 'ADAM John WATTS',
      grade: "Ricardo Spap",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "16mm",
      thumbnails: {
        placeholder: "../media/main/tiptoes/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/tiptoes/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/tiptoes/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/tiptoes/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/tiptoes/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/tiptoes/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1,2],
      stills: [
        {
          backgroundImage: "../media/main/tiptoes/stills/mobile/still1.webp",
          placeholder: "../media/main/tiptoes/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/tiptoes/stills/mobile/still2.webp",
          placeholder: "../media/main/tiptoes/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/tiptoes/stills/mobile/still3.webp",
          placeholder: "../media/main/tiptoes/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/tiptoes/stills/mobile/still4.webp",
          placeholder: "../media/main/tiptoes/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/tiptoes/stills/mobile/still5.webp",
          placeholder: "../media/main/tiptoes/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/tiptoes/stills/mobile/still6.webp",
          placeholder: "../media/main/tiptoes/stills/placeholder/still6.webp"
        },
        {
          backgroundImage: "../media/main/tiptoes/stills/mobile/still7.webp",
          placeholder: "../media/main/tiptoes/stills/placeholder/still7.webp"
        },
        {
          backgroundImage: "../media/main/tiptoes/stills/mobile/still8.webp",
          placeholder: "../media/main/tiptoes/stills/placeholder/still8.webp"
        }
      ]
    },
    {
      title: "ANY MINUTE",
      artist: "M Huncho ft. Slim",
      vimeo: "https://vimeo.com/921484022/6a9ad5571a?share=copy",
      runTime: [3, 15],
      equipment: "Arri Sr3 & Bolex EBM",
      date: "Summer '23",
      director: "TeeeezyC",
      dop: 'MICHAEL R JOHNSON',
      editor: 'TeeeezyC',
      producer: 'Odyssey ',
      grade: "Alexander Nerzic",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "16mm & Zeiss Superspeeds",
      thumbnails: {
        placeholder: "../media/main/anyminute/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/anyminute/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/anyminute/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/anyminute/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/anyminute/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/anyminute/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1, 4],
      stills: [
        {
          backgroundImage: "../media/main/anyminute/stills/mobile/still1.webp",
          placeholder: "../media/main/anyminute/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/anyminute/stills/mobile/still2.webp",
          placeholder: "../media/main/anyminute/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/anyminute/stills/mobile/still3.webp",
          placeholder: "../media/main/anyminute/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/anyminute/stills/mobile/still4.webp",
          placeholder: "../media/main/anyminute/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/anyminute/stills/mobile/still5.webp",
          placeholder: "../media/main/anyminute/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/anyminute/stills/mobile/still6.webp",
          placeholder: "../media/main/anyminute/stills/placeholder/still6.webp"
        }
      ]
    },
    // {
    //   title: "TRAPSTAR LAND AIR SEA",
    //   artist: "Trapstar",
    //   vimeo: "",
    //   runTime: [2, 50],
    //   equipment: "Alexa Mini + Cooke S4's & TLS Zoom's",
    //   date: "Autumn '23",
    //   director: "Don Prod",
    //   dop: 'MICHAEL R JOHNSON',
    //   editor: 'Jack Millward',
    //   producer: 'Adam John Watts',
    //   grade: "Yunis",
    //   location: "Southampton",
    //   shotOn: "Arri Alexa",
    //   coords: ['50.9105° N', '1.4049° W'],
    //   thumbnails: {
    //     placeholder: "../media/main/trapstarLandAirSea/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
    //     tile: "../media/main/trapstarLandAirSea/thumbnails/tile.webp", // used as final image for home tiles (400px)
    //     mobile: "../media/main/trapstarLandAirSea/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
    //     laptop: "../media/main/trapstarLandAirSea/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
    //     desktop: "../media/main/trapstarLandAirSea/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
    //   },
    //   gifStyling: {
    //     backgroundImage: "../media/main/trapstarLandAirSea/gif/thumb_gif.gif" // The gif used for the thumbnail
    //   },
    //   tags : [2, 3, 4],
    //   stills: [
    //     {
    //       backgroundImage: "../media/main/trapstarLandAirSea/stills/mobile/still1.webp",
    //       placeholder: "../media/main/trapstarLandAirSea/stills/placeholder/still1.webp"
    //     },
    //     {
    //       backgroundImage: "../media/main/trapstarLandAirSea/stills/mobile/still2.webp",
    //       placeholder: "../media/main/trapstarLandAirSea/stills/placeholder/still2.webp"
    //     },
    //     {
    //       backgroundImage: "../media/main/trapstarLandAirSea/stills/mobile/still3.webp",
    //       placeholder: "../media/main/trapstarLandAirSea/stills/placeholder/still3.webp"
    //     },
    //     {
    //       backgroundImage: "../media/main/trapstarLandAirSea/stills/mobile/still4.webp",
    //       placeholder: "../media/main/trapstarLandAirSea/stills/placeholder/still4.webp"
    //     },
    //     {
    //       backgroundImage: "../media/main/trapstarLandAirSea/stills/mobile/still5.webp",
    //       placeholder: "../media/main/trapstarLandAirSea/stills/placeholder/still5.webp"
    //     },
    //     {
    //       backgroundImage: "../media/main/trapstarLandAirSea/stills/mobile/still6.webp",
    //       placeholder: "../media/main/trapstarLandAirSea/stills/placeholder/still6.webp"
    //     },
    //     {
    //       backgroundImage: "../media/main/trapstarLandAirSea/stills/mobile/still7.webp",
    //       placeholder: "../media/main/trapstarLandAirSea/stills/placeholder/still7.webp"
    //     }
    //   ]
    // },
    {
      title: "STRAIGHT OUTTA NEWHAM",
      artist: "Berna",
      vimeo: "https://vimeo.com/922171887/baba99509e?share=copy",
      runTime: [2, 50],
      equipment: "Alexa Mini + Cooke S4's",
      date: "Spring '23",
      director: "Don Prod",
      dop: 'MICHAEL R JOHNSON',
      editor: 'DON Prod',
      producer: 'Don Prod',
      grade: "MICHAEL R JOHNSON",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/straightouttanewham/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/straightouttanewham/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/straightouttanewham/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/straightouttanewham/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/straightouttanewham/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/straightouttanewham/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1, 3],
      stills: [
        {
          backgroundImage: "../media/main/straightouttanewham/stills/mobile/still1.webp",
          placeholder: "../media/main/straightouttanewham/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/straightouttanewham/stills/mobile/still2.webp",
          placeholder: "../media/main/straightouttanewham/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/straightouttanewham/stills/mobile/still3.webp",
          placeholder: "../media/main/straightouttanewham/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/straightouttanewham/stills/mobile/still4.webp",
          placeholder: "../media/main/straightouttanewham/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/straightouttanewham/stills/mobile/still5.webp",
          placeholder: "../media/main/straightouttanewham/stills/placeholder/still5.webp"
        }
      ]
    },
    {
      title: "KINDNESS FOR WEAKNESS",
      vimeo: "https://vimeo.com/900528906/ee7c5f08db?share=copy",
      artist: "DIGGA D",
      runTime: [2, 3],
      equipment: "Alexa Mini + Cooke S4's",
      date: "Autumn '23",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Jack Millward',
      producer: 'Adam John Watts',
      grade: "Michael R. Johnson",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/diggadkindnessforweakness/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/diggadkindnessforweakness/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/diggadkindnessforweakness/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/diggadkindnessforweakness/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/diggadkindnessforweakness/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/diggadkindnessforweakness/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1, 4],
      stills: [
        {
          backgroundImage: "../media/main/diggadkindnessforweakness/stills/mobile/still1.webp",
          placeholder: "../media/main/diggadkindnessforweakness/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/diggadkindnessforweakness/stills/mobile/still2.webp",
          placeholder: "../media/main/diggadkindnessforweakness/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/diggadkindnessforweakness/stills/mobile/still3.webp",
          placeholder: "../media/main/diggadkindnessforweakness/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/diggadkindnessforweakness/stills/mobile/still4.webp",
          placeholder: "../media/main/diggadkindnessforweakness/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/diggadkindnessforweakness/stills/mobile/still5.webp",
          placeholder: "../media/main/diggadkindnessforweakness/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/diggadkindnessforweakness/stills/mobile/still6.webp",
          placeholder: "../media/main/diggadkindnessforweakness/stills/placeholder/still6.webp"
        }
      ]
    },
    {
      title: "WIN OR LOSE",
      artist: "Lil Mack's",
      vimeo: "https://vimeo.com/921484763/ddad4f8683?share=copy",
      runTime: [2, 10],
      equipment: "Alexa Mini w/ Cooke S4's",
      date: "Spring '23",
      director: "Mani J",
      dop: 'MICHAEL R JOHNSON',
      editor: 'Odyssey',
      producer: 'Odyssey',
      grade: "Michael Richard Johnson",
      location: "Luton",
      coords: ['51.8787° N', '0.4200° W'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/winorlose/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/winorlose/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/winorlose/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/winorlose/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/winorlose/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/winorlose/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1],
      stills: [
        {
          backgroundImage: "../media/main/winorlose/stills/mobile/still1.webp",
          placeholder: "../media/main/winorlose/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/winorlose/stills/mobile/still2.webp",
          placeholder: "../media/main/winorlose/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/winorlose/stills/mobile/still3.webp",
          placeholder: "../media/main/winorlose/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/winorlose/stills/mobile/still4.webp",
          placeholder: "../media/main/winorlose/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/winorlose/stills/mobile/still5.webp",
          placeholder: "../media/main/winorlose/stills/placeholder/still5.webp"
        }
      ]
    },
    {
      title: "Hennesy",
      artist: "Eric IV ft. Blxst",
      vimeo: "https://vimeo.com/923423990/68b2772941?share=copy",
      runTime: [2, 11],
      equipment: "Alexa Mini + Cooke S4's",
      date: "Summer '23",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Jack Millward',
      producer: 'Jack Millward',
      grade: "Michael R. Johnson",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/hennesy/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/hennesy/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/hennesy/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/hennesy/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/hennesy/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/hennesy/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1],
      stills: [
        {
          backgroundImage: "../media/main/hennesy/stills/mobile/still1.webp",
          placeholder: "../media/main/hennesy/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/hennesy/stills/mobile/still2.webp",
          placeholder: "../media/main/hennesy/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/hennesy/stills/mobile/still3.webp",
          placeholder: "../media/main/hennesy/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/hennesy/stills/mobile/still4.webp",
          placeholder: "../media/main/hennesy/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/hennesy/stills/mobile/still5.webp",
          placeholder: "../media/main/hennesy/stills/placeholder/still5.webp"
        }
      ]
    },
    {
      title: "Money and Vibes",
      artist: "Nems",
      vimeo: "https://vimeo.com/923418146/20ee1e057d?share=copy",
      runTime: [2, 1],
      equipment: "Alexa Mini + Cooke S4's",
      date: "Summer '23",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Jack Millward',
      producer: 'Jack Millward',
      grade: "Michael R. Johnson",
      location: "North Cyprus",
      coords: ['35.2480° N', '33.6577° E'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/moneyandvibes/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/moneyandvibes/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/moneyandvibes/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/moneyandvibes/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/moneyandvibes/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/moneyandvibes/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1],
      stills: [
        {
          backgroundImage: "../media/main/moneyandvibes/stills/mobile/still1.webp",
          placeholder: "../media/main/moneyandvibes/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/moneyandvibes/stills/mobile/still2.webp",
          placeholder: "../media/main/moneyandvibes/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/moneyandvibes/stills/mobile/still3.webp",
          placeholder: "../media/main/moneyandvibes/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/moneyandvibes/stills/mobile/still4.webp",
          placeholder: "../media/main/moneyandvibes/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/moneyandvibes/stills/mobile/still5.webp",
          placeholder: "../media/main/moneyandvibes/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/moneyandvibes/stills/mobile/still6.webp",
          placeholder: "../media/main/moneyandvibes/stills/placeholder/still6.webp"
        }
      ]
    },
    {
      title: "E11EVEN",
      vimeo: "https://vimeo.com/923424650/3ac8739419?share=copy",
      artist: "K1 NFL",
      runTime: [4, 1],
      equipment: "Alexa Mini + Cooke S4's",
      date: "Summer '23",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Jack Millward',
      producer: 'Adam John Watts',
      grade: "Michael R. Johnson",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/elleven/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/elleven/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/elleven/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/elleven/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/elleven/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/elleven/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1, 4],
      stills: [
        {
          backgroundImage: "../media/main/elleven/stills/mobile/still1.webp",
          placeholder: "../media/main/elleven/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/elleven/stills/mobile/still2.webp",
          placeholder: "../media/main/elleven/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/elleven/stills/mobile/still3.webp",
          placeholder: "../media/main/elleven/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/elleven/stills/mobile/still4.webp",
          placeholder: "../media/main/elleven/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/elleven/stills/mobile/still5.webp",
          placeholder: "../media/main/elleven/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/elleven/stills/mobile/still6.webp",
          placeholder: "../media/main/elleven/stills/placeholder/still6.webp"
        }
      ]
    },
    {
      title: "Levels",
      vimeo: "https://vimeo.com/923416807/7a468107d4?share=copy",
      artist: "Chinx OS",
      runTime: [3, 8],
      equipment: "Alexa Mini + Cooke S4's",
      date: "Summer '23",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Don Prod',
      producer: 'Don Prod',
      grade: "Michael R. Johnson",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/levels/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/levels/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/levels/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/levels/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/levels/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/levels/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1],
      stills: [
        {
          backgroundImage: "../media/main/levels/stills/mobile/still1.webp",
          placeholder: "../media/main/levels/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/levels/stills/mobile/still2.webp",
          placeholder: "../media/main/levels/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/levels/stills/mobile/still3.webp",
          placeholder: "../media/main/levels/stills/placeholder/still3.webp"
        }
      ]
    },
    {
      title: "Illegal",
      vimeo: "https://vimeo.com/923422183/b865b403d1?share=copy",
      artist: "Headie One",
      runTime: [3, 39],
      equipment: "Alexa Mini + Arri Ultra Primes",
      date: "Summer '22",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Don Prod',
      producer: 'Don Prod',
      grade: "Michael R. Johnson",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/illegal/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/illegal/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/illegal/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/illegal/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/illegal/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/illegal/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1],
      stills: [
        {
          backgroundImage: "../media/main/illegal/stills/mobile/still1.webp",
          placeholder: "../media/main/illegal/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/illegal/stills/mobile/still2.webp",
          placeholder: "../media/main/illegal/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/illegal/stills/mobile/still3.webp",
          placeholder: "../media/main/illegal/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/illegal/stills/mobile/still4.webp",
          placeholder: "../media/main/illegal/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/illegal/stills/mobile/still5.webp",
          placeholder: "../media/main/illegal/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/illegal/stills/mobile/still6.webp",
          placeholder: "../media/main/illegal/stills/placeholder/still6.webp"
        }
      ]
    },
    {
      title: "Be A Hoe",
      vimeo: "https://vimeo.com/923436707/5d6e251cfb?share=copy",
      artist: "Nafe Smalls ft Lancey Faux ",
      runTime: [3, 9],
      equipment: "Alexa Mini + Cooke S4's",
      date: "Winter '23",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Don Prod',
      producer: 'Don Prod',
      grade: "Jared Rosenthall",
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/be_a_hoe/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/be_a_hoe/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/be_a_hoe/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/be_a_hoe/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/be_a_hoe/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/be_a_hoe/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1],
      stills: [
        {
          backgroundImage: "../media/main/be_a_hoe/stills/mobile/still1.webp",
          placeholder: "../media/main/be_a_hoe/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/be_a_hoe/stills/mobile/still2.webp",
          placeholder: "../media/main/be_a_hoe/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/be_a_hoe/stills/mobile/still3.webp",
          placeholder: "../media/main/be_a_hoe/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/be_a_hoe/stills/mobile/still4.webp",
          placeholder: "../media/main/be_a_hoe/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/be_a_hoe/stills/mobile/still5.webp",
          placeholder: "../media/main/be_a_hoe/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/be_a_hoe/stills/mobile/still6.webp",
          placeholder: "../media/main/be_a_hoe/stills/placeholder/still6.webp"
        },
        {
          backgroundImage: "../media/main/be_a_hoe/stills/mobile/still7.webp",
          placeholder: "../media/main/be_a_hoe/stills/placeholder/still7.webp"
        },
        {
          backgroundImage: "../media/main/be_a_hoe/stills/mobile/still8.webp",
          placeholder: "../media/main/be_a_hoe/stills/placeholder/still8.webp"
        }
      ]
    },
    {
      title: "Local",
      vimeo: "https://vimeo.com/923418701/584b8ccf9f?share=copy",
      artist: "Abracadabra",
      runTime: [5, 12],
      location: "London",
      coords: ['51.5072° N', '0.1276° W'],
      shotOn: "Arri Alexa",
      equipment: "Arri Alexa",
      date: "Summer '22",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Don Prod',
      producer: 'Don Prod',
      grade: "Michael R. Johnson",
      thumbnails: {
        placeholder: "../media/main/local/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/local/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/local/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/local/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/local/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/local/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1],
      stills: [
        {
          backgroundImage: "../media/main/local/stills/mobile/still1.webp",
          placeholder: "../media/main/local/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/local/stills/mobile/still2.webp",
          placeholder: "../media/main/local/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/local/stills/mobile/still3.webp",
          placeholder: "../media/main/local/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/local/stills/mobile/still4.webp",
          placeholder: "../media/main/local/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/local/stills/mobile/still5.webp",
          placeholder: "../media/main/local/stills/placeholder/still5.webp"
        },
      ]
    },
    {
      title: "Link In Ends",
      vimeo: "https://vimeo.com/921532257/e63463f3e4?share=copy",
      artist: "Headie One",
      runTime: [2, 55],
      equipment: "Alexa Mini + Arri Alura Zooms",
      date: "Autumn '22",
      director: "Don Prod",
      dop: 'MICHAEL R. JOHNSON',
      editor: 'Don Prod',
      producer: 'Adam John Watts',
      grade: "Michael R. Johnson",
      location: "London & Paris",
      coords: ['48.8566° N', '2.3522° E'],
      shotOn: "Arri Alexa",
      thumbnails: {
        placeholder: "../media/main/linkinends/thumbnails/placeholder.webp", // used as placeholder for home tiles (100px)
        tile: "../media/main/linkinends/thumbnails/tile.webp", // used as final image for home tiles (400px)
        mobile: "../media/main/linkinends/thumbnails/mobile.webp", //used as hero on shoot page for mobile (600px)
        laptop: "../media/main/linkinends/thumbnails/laptop.webp", //used as hero on shoot page for mobile (1200px)
        desktop: "../media/main/linkinends/thumbnails/desktop.webp", //used as hero on shoot page for mobile (3840px)
      },
      gifStyling: {
        backgroundImage: "../media/main/linkinends/gif/thumb_gif.gif" // The gif used for the thumbnail
      },
      tags : [1, 3],
      stills: [
        {
          backgroundImage: "../media/main/linkinends/stills/mobile/still1.webp",
          placeholder: "../media/main/linkinends/stills/placeholder/still1.webp"
        },
        {
          backgroundImage: "../media/main/linkinends/stills/mobile/still2.webp",
          placeholder: "../media/main/linkinends/stills/placeholder/still2.webp"
        },
        {
          backgroundImage: "../media/main/linkinends/stills/mobile/still3.webp",
          placeholder: "../media/main/linkinends/stills/placeholder/still3.webp"
        },
        {
          backgroundImage: "../media/main/linkinends/stills/mobile/still4.webp",
          placeholder: "../media/main/linkinends/stills/placeholder/still4.webp"
        },
        {
          backgroundImage: "../media/main/linkinends/stills/mobile/still5.webp",
          placeholder: "../media/main/linkinends/stills/placeholder/still5.webp"
        },
        {
          backgroundImage: "../media/main/linkinends/stills/mobile/still6.webp",
          placeholder: "../media/main/linkinends/stills/placeholder/still6.webp"
        }
      ]
    }
  ]