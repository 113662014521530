import React from "react";

function ShootInfoHeader({infoName, children}){
    return (
        <>
            {
                infoName ?
                    <span className={infoName ? "" : "redacted redact-in"}>
                        {children}
                    </span>
                :

                <del className="relative-del">
                    <span className={infoName ? "" : "redacted redact-in"}>
                        {children}
                    </span>
                </del>
            }
        </>
    )
}

export default ShootInfoHeader;