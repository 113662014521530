import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import './shoot.css';
import Vimeo from '@u-wave/react-vimeo';
import { Link } from "react-router-dom";
import { gridItems } from "../../globals/data";
import ProgressiveBackgroundImg from "../../components/ProgressiveBackgroundImg/ProgressiveBackgroundImg";
import ProgressiveImageItem from "../../components/ProgressiveImageItem/ProgressiveImageItem";
import { getDevice, sanitiser } from "../../globals/helpers";
import ResponsiveText from "../../components/ResponsiveText/ResponsiveText";
import { deviceType, primaryInput } from "detect-it";
import ShootInfoHeader from "../../components/ShootInfoHeader/ShootInfoHeader";
import { minuteRepresentation } from "../../globals/helpers";
import { pausedOverlayParentVariants, pausedOverlayVariants, playIconsVariants } from "../../globals/variants";
import ErrorBoundary from "../../components/errorBoundary/ErrorBoundary";
import { workTags } from "../../globals/data";

const videoAnimDuration = 0.6;
let isVidActive = false; 
let neighbourProj = null;

const footerVariants = {
    initial: i => ({
        opacity: 0
    }),
    animate: i => ({
        opacity: i ? 0 : 1,
    }),
    exit: {
        opacity: 0
    }
}

const footerItemVariants = {
    initial: i => ({
        opacity: 1,
        transform: 'translate3d(0%, 100%, 0)',
    }),
    animate: i => ({
        opacity: 1,
        transform: 'translate3d(0%, 0%, 0)',
        transition: {
            duration: 1.25,
            delay: 0.5 + 0.15 * i,
            ease: [0.16, 1, 0.3, 1]
        }
    }),
    exit: {
        opacity: 0
    }
}

function Shoot({projID, setCursorText, setIsLoading, isOutdatedSafari}) {
    const location = useLocation();
    let prevLoc;
    try{
        prevLoc = location.state.previousPath;
    }
    catch{
        prevLoc = ''
    }

    let isCursorText = true
    const [videoPlayback, setVideoPlayback] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const clickSignifierRef = useRef(null);
    const scrollTarg = useState(null);
    const heroRef = useRef(null);
    const openerRef = useRef(null);
    const landingRef = useRef(null);
    const videoRef = useRef(null);
    const overlayRef = useRef(null);
    const progressBar = useRef(null);
    const curTimeRef = useRef(null);
    const endTimeRef = useRef(null);
    const [duration, setDuration] = useState(null);
    const [isMuted, setIsMuted] = useState(false)
    const [reactPlayer, setReactPlayer] = useState(null);
    const [viewDevice, setViewDevice] = useState(null);
    const shootNumRef = useRef(0);
    const [isFirefox, setIsFirefox] = useState(false);

    let selectedData;
    let projectIndex = 0;

    const imgHeroVariants = {
        initial: {
            opacity: prevLoc=="home" ? 1 : 0,
            y:  "-50%",
            x: '-50%',
            height: window.innerWidth/window.innerHeight < 1.5 ? 
                prevLoc=="home" ? 
                        (window.innerWidth <= 860 ? window.innerWidth / 2 : 
                            window.innerWidth <= 1180 ? window.innerWidth / 4 : 
                                window.innerWidth / 5 + "px") 
                    : "100%" 
                : '',
            width: window.innerWidth/window.innerHeight > 1.5 ? prevLoc=="home" ? (window.innerWidth <= 860 ? window.innerWidth / 2 : window.innerWidth <= 1180 ? window.innerWidth / 4 : window.innerWidth / 5 + "px") : "100vw" : '',
            transformOrigin: "center",
        },
        animate: {
            opacity: 1,
            y: "-50%",
            x: '-50%',
            height: window.innerWidth/window.innerHeight < 1.5 ? '100%' : '',
            width: window.innerWidth/window.innerHeight > 1.5 ? '100vw' : '',
            transition: {
                duration: videoAnimDuration,
                duration: 0.95,
                ease: [0.16, 1, 0.3, 1]
            }
        },
        exit: {
            opacity: 0,
            height: window.innerWidth/window.innerHeight < 1.5 ? '100%' : '',
            width: window.innerWidth/window.innerHeight > 1.5 ? '100vw' : '',
            transition: {
                duration: videoAnimDuration,
                ease: "easeOut"
            }
        }
    }

    const vidHeroVariants = {
        initial: {
            opacity: 0,
            transform: "translate3d(0, -50%, 0) scale(1,1)"
        },
        animate: {
            opacity: 1,
            transform: `translate3d(0, -50%, 0) scale(${viewDevice=='mobile' || viewDevice=='tablet' ? '1, 1' : '.8'})`,
            transition: {
                duration: videoAnimDuration,
                delay: 0.1,
                ease: [0.33, 1, 0.68, 1]
            }
        },
        exit: {
            opacity: 1,
            transform: "translate3d(0, -50%, 0) scale(1,1)",
            transition: {
                duration: videoAnimDuration,
                ease: [0.33, 1, 0.68, 1]
            }
        }
    }

    const shootWrapperVariants = {
        initial: {
            opacity: 1
        },
        animate: {
            opacity: 1
        },
        exit: {
            opacity: 0
        }
    }

    for (let iterI = 0; iterI < gridItems.length; iterI++) {
        let targMatch = sanitiser(gridItems[iterI]['title']);
        if (targMatch==projID) {
            projectIndex = (iterI + 1).toString();
            if (targMatch==projID) {
                
                if (projectIndex < 10)
                    projectIndex = "0" + projectIndex 
                shootNumRef.innerText = projectIndex;
                selectedData = gridItems[iterI];
                let prevIdx = iterI==0 ? gridItems.length - 1: iterI - 1;
                let nextIdx = iterI==gridItems.length - 1 ? 0 : iterI + 1;
                neighbourProj = {
                    'prev': {
                        'idx' : prevIdx,
                        'title': gridItems[prevIdx].title,
                        'link': gridItems[prevIdx].title,
                        'thumbnail': gridItems[prevIdx].thumbnails.mobile,
                        'placeholder': gridItems[prevIdx].thumbnails.placeholder
                    },
                    'next': {
                        'idx': nextIdx,
                        'title': gridItems[nextIdx].title,
                        'link': gridItems[nextIdx].title,
                        'thumbnail': gridItems[nextIdx].thumbnails.mobile,
                        'placeholder': gridItems[nextIdx].thumbnails.placeholder
                    }
                }
            }   
        }
    }

    function openVideoOverlay(e){
        let navbar;
        try{
            navbar = document.getElementById("navbar");
        } catch{}
        isVidActive = true;
        try{
            openerRef.current.style.pointerEvents = "none";
            heroRef.current.style.pointerEvents = "all";
        }catch{}
        try{
            clickSignifierRef.current.classList.add("hide");
        } catch(err){
            console.log(err)
        }

        setVideoPlayback(true);
        setIsVideoPlaying(true);

        try{
            navbar.style.pointerEvents = 'none';
            navbar.classList.add("hide");
            navbar.classList.add('no-interaction');
        } catch{}

        // if(videoRef)
        //     videoRef.current.style.filter = 'grayscale(0)';

        setTimeout(() => {
            if(videoRef)
                videoRef.current.style.zIndex = 2;
        }, videoAnimDuration*1000)
    }

    function handleImageClick(e) {
        try{
            landingRef.current.style.zIndex = 0;
        } catch{}
        try{
            heroRef.current.style.zIndex = 5;
        } catch{}
        e.preventDefault();
        e.stopPropagation();

        try{
            if (heroRef.current.style.opacity > 0.9) {
                openVideoOverlay(e)
            }
        } catch{}
    }

    function handleOverlayClick(e){
        e.stopPropagation();
        e.preventDefault();
        if(isVideoPlaying){
            try{setCursorText("PLAY")} catch{}
            // try{videoRef.current.style.filter = 'grayscale(1)'}catch{}
            try{isVidActive = false}catch{}
        }
        else{
            try{setCursorText("PAUSE")}catch{}
            // try{videoRef.current.style.filter = 'grayscale(0)'}catch{}
            try{isVidActive = true}catch{}
        }
        setIsVideoPlaying(!isVideoPlaying);
    }

    function closeVideoOverlay(e){
        e.stopPropagation();
        e.preventDefault();
        if(landingRef){
            try{
                landingRef.current.style.zIndex = 5;
            } catch{}
        }
        if(heroRef){
            try{heroRef.current.style.zIndex = 0}catch{}
        }
        let navbar;
        let scrollEle;

        try{
            let pausedOverlays = document.getElementsByClassName("paused-overlay");
            for (let i = 0; i < pausedOverlays.length; i++){
                let tmpPO = pausedOverlays[i];
                tmpPO.classList.add("hide");
            }
        }catch{}

        try{
            navbar = document.getElementById("navbar");
            scrollEle = document.getElementById("content-scroll");
        } catch{}
        if(navbar){
            navbar.classList.remove('no-interaction');
            navbar.classList.remove("hide");
        }
        if(scrollEle){
            scrollEle.classList.remove('no-interaction');
        }
        if(isVidActive){
            setIsVideoPlaying(false);
        }
        setVideoPlayback(false);

        if(openerRef){
            try{openerRef.current.style.pointerEvents = "all"}catch{}
        }
        if(heroRef){
            try{heroRef.current.style.pointerEvents = "none"}catch{}
        }
        if(videoRef){
            try{videoRef.current.style.zIndex = 0}catch{}
        }
        isVidActive = false;
    }

    function handleLandingClicked(e) {
        e.stopPropagation();
        e.preventDefault();
        if (["mobile", "tablet"].includes(viewDevice) || deviceType=="touchOnly" || primaryInput=="touch" ){
            if(isVideoPlaying){
                setCursorText("PLAY");
                // videoRef.current.style.filter = 'grayscale(1)';
            }
            else{
                setCursorText("PAUSE");
                // videoRef.current.style.filter = 'grayscale(0)';
            }
            setIsVideoPlaying(!isVideoPlaying);
        } else{
            closeVideoOverlay(e);
        }
    }

    function handleScroll(checkDev, scrollAmt, footer) {
        let scrollVal = scrollAmt.y;
        if (scrollVal < (window.innerHeight) && !isVidActive) {
            let newOpacity = (1 - 1.1 * (scrollVal / window.innerHeight)).toPrecision(3);
            
            if (checkDev !== "mobile" && checkDev !== "tablet"){
                if (newOpacity < 0.9 && isCursorText){
                    setCursorText("");
                    isCursorText = false;
                } else if(newOpacity >= 0.9 && !isCursorText){
                    setCursorText("PLAY");
                    isCursorText = true;
                }
            }
            if(scrollVal < window.innerHeight){
                footer.style.transform = "translate3d(0, " + (scrollVal / window.innerHeight) * 100 + "px, 0)";
                footer.style.opacity = (1 - 2.5 * (scrollVal / window.innerHeight)).toPrecision(3);
            }
            if (heroRef.current) {
                heroRef.current.style.opacity = newOpacity;
                if (newOpacity==0)
                    heroRef.current.pointerEvents = "all"
                else {
                    heroRef.current.pointerEvents = "none"
                }
            }
        }
    }

    function handleResize(){
        try{
            setViewDevice(getDevice());
            let scrollEle = document.getElementById("content-scroll");
            scrollEle.classList.remove('no-interaction');
            
        } catch(err){
            
        }
    }
    
    
    useEffect(() => {
        let nVer;
        let nAgt;
        let browserName;
        let fullVersion;
        let majorVersion;
        let verOffset;
        nVer = navigator.appVersion;
        nAgt = navigator.userAgent;
        browserName  = navigator.appName;
        fullVersion  = ''+parseFloat(navigator.appVersion); 
        majorVersion = parseInt(navigator.appVersion,10);

        if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset+8);
            setIsFirefox(true);
        }


        // document.documentElement.style.setProperty("overflow-y", "hidden");
        document.getElementsByTagName('body')[0].style.overflow = 'visible';
        // let tileMultiplier;
        // let checkDev = getDevice();
        // if (window.innerWidth <= 860)
        //     tileMultiplier = 2;
        // else if(checkDev <= 1180){
        //     tileMultiplier = 4;
        // }
        // else{
        //     tileMultiplier = 5;
        // }

        const unloadCallback = (event) => {
            window.history.replaceState({}, '')
        };
        let scroll;
        let testInterval = setInterval(() => {
            try {
                let targ = document.getElementsByClassName("lower-wrapper")[0];
                targ.remove()
            }
            catch (err) {
    
            }
            let footer = document.getElementById("shoot-footer");
            let scrollTargEle = document.getElementById('content-scroll');
            if (footer && scrollTargEle){
                scroll = new LocomotiveScroll({
                    el: scrollTargEle,
                    gestureDirection: "vertical",
                    smooth: true,
                    lerp: 0.05,
                    touchMultiplier: 4,
                    smartphone: {
                        smooth: true,
                        direction: 'vertical'
                    },
                    tablet: {
                        smooth: true,
                        direction: 'vertical',
            
                    },
                    reloadOnContextChange: true
                });
                scroll.destroy()
                let passDev = getDevice();
                setTimeout(function () {
                    scroll.init()
                    let notAdjustingScroll = true;
                    scroll.on('scroll', (args) => {
                        try{
                            handleScroll(passDev, args.scroll, footer)
                            if (args.scroll.y==args.limit.y && notAdjustingScroll){
                                notAdjustingScroll = false;
                                setTimeout(() => {
                                notAdjustingScroll = true;
                                }, 250)
                                scroll.scrollTo(
                                'bottom', {
                                    'offset': -2,
                                    'duration': 0.25,
                                }
                                )
                            }
                        } catch{}
                    });
                }, 150);
                clearInterval(testInterval)
            }
        }, 200)
        window.addEventListener('beforeunload', unloadCallback);
        handleResize();
        window.addEventListener('resize', (e) => handleResize(e), true);
        setCursorText("PLAY")
        return () => {
            try{
                scroll.destroy()
                window.history.replaceState({previousPath: location.pathname}, document.title)
                window.removeEventListener('beforeunload', unloadCallback);
                try{
                    window.removeEventListener('resize', handleResize);
                }catch{
                }
                let navbar = document.getElementById("navbar");
                navbar.style.pointerEvents = 'none';
                navbar.classList.remove('no-interaction');
                navbar.classList.remove("hide");
                setCursorText("")
            }catch{

            }
        };
    }, []) 

    function handleLandingHover(e){
        try{
            setCursorText("CLOSE")
        } catch{}
    }

    function handleReady(player){
        try{setReactPlayer(player)}catch{}
    }

    function handleNextPrevEnter(e){
        
    }

    function handleNextPrevLeave(e){
        
    }

    function handleTimerUpdate(e){
        if(duration==null){
            setDuration(e.duration)
            endTimeRef.current.innerHTML = minuteRepresentation(e.duration)
        }
        curTimeRef.current.innerHTML = minuteRepresentation(e.seconds)
        let percent = e.percent;
        progressBar.current.style.width = percent * 100 + "%"
    }

    function handleProgressClick(e){
        e.stopPropagation();
        let xRatio;
        let jumpTo;
        try{xRatio = (e.clientX - e.target.offsetLeft) / e.target.offsetWidth}catch{}
        try{jumpTo = Math.floor(xRatio * duration)}catch{}
        try{
            if(progressBar.current)
                progressBar.current.style.width = xRatio * 100 + "%"
        }catch{}
        try{reactPlayer.setCurrentTime(jumpTo)}catch{}
    }

    try{
        return (
            <ErrorBoundary>
                {
                    // (!location.state || location.state.previousPath !=="home") ? 
                    //     <>sheeesh</>
                    // :
                        <motion.div 
                            key={"shootKey"}
                            className="shoot-page__wrapper"
                            initial="initial"    
                            animate="animate"
                            exit="exit"
                            variants={shootWrapperVariants}
                        >
                            <div 
                                className="main-landing__wrapper" 
                                id='shoot-main-landing__wrapper' 
                                
                                ref={scrollTarg}
                                key="scroll=landing-targ"
                                // onClick={(e) => handleLandingClicked(e)} 
                                // onMouseEnter={(e) => {handleLandingHover(e)}}
                            >
                                <div className="shoot-hero" ref={heroRef} style={{ opacity: 1 }}>
                                    {
                                        (deviceType=="touchOnly" || primaryInput=='touch') && !isVideoPlaying && !videoPlayback &&
                                        <motion.div 
                                            ref={clickSignifierRef}
                                            className="shoot-click-signifier"
                                            initial={{
                                                opacity:0
                                            }}
                                            animate={{
                                                opacity: 1,
                                                transition: {
                                                    duration: 0.65,
                                                    delay: 0.75
                                                }
                                            }}
                                            exit={{
                                                opacity: 0,
                                                transition: {
                                                    duration: 0.5,
                                                    delay: 0
                                                }
                                            }}
                                        >
                                            <svg 
                                                fill="white"
                                                height="100px"
                                                width="100px"
                                                version="1.1"
                                                id="Capa_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 60 60"
                                                xmlSpace="preserve"
                                            >
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier"> 
                                                        <g>
                                                            <path d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30 c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15 C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"></path>
                                                            <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30 S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"></path>
                                                        </g>
                                                    </g>
                                            </svg>
                                        </motion.div>
                                    }
                                    <AnimatePresence mode="wait">
                                    {
                                        !isVideoPlaying && videoPlayback && curTimeRef.current &&
                                        <>
                                            <motion.div 
                                                key={"paused-ele-1" + !isVideoPlaying && videoPlayback && curTimeRef.current}
                                                className="paused-overlay"
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                variants={pausedOverlayParentVariants}
                                                style={{color: 'black', opacity: '0.5', mixBlendMode: 'normal'}}
                                            >
                                                <ResponsiveText 
                                                    key={'r-t-2-'+curTimeRef.current.innerHTML}
                                                    text={curTimeRef.current.innerHTML} 
                                                    propVariants={pausedOverlayVariants}
                                                />
                                            </motion.div>
                                            <motion.div 
                                                key={"paused-ele-2" + !isVideoPlaying && videoPlayback && curTimeRef.current}
                                                className="paused-overlay"
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                variants={pausedOverlayParentVariants}
                                            >
                                                <ResponsiveText 
                                                    key={'r-t-2-'+curTimeRef.current.innerHTML} text={curTimeRef.current.innerHTML} 
                                                    propVariants={pausedOverlayVariants}
                                                />
                                            </motion.div>
                                        </>
                                    }
                                    </AnimatePresence>
                                    {
                                        (viewDevice=='mobile' || viewDevice=="tablet" || primaryInput=="touch")  &&
                                            <motion.div 
                                                className="mobile-control__wrapper padding-gutter"
                                                initial={{
                                                    opacity: 0,
                                                    zIndex: 0
                                                }}
                                                animate={{
                                                    opacity: videoPlayback ? 1 : 0,
                                                    zIndex: 5,
                                                    transition:{
                                                        duration: 0.75,
                                                        ease: 'easeOut'
                                                    }
                                                }}
                                                exit="exit"
                                                
                                            >
                                                <span 
                                                    className="close-option"
                                                    onClick={(e) => {
                                                        closeVideoOverlay(e);
                                                    }}
                                                >CLOSE</span>
                                                <motion.div
                                                    className="sound-motion-mob" 
                                                    key="vimeo-sound-mob"
                                                >
                                                    <motion.div
                                                        className="sound-strikethrough" 
                                                        style={{
                                                            width: `${isMuted ? "100%" : "0%"}`,
                                                            left: `${isMuted ? "0" : ""}`,
                                                            right: `${isMuted ? "" : "0"}`,
                                                        }}
                                                    />
                                                    <span className="sound-option-mob"
                                                        onClick={(e) => {
                                                            setIsMuted(!isMuted);
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        onMouseEnter={() => {setCursorText("")}}
                                                    >SOUND</span>
                                                </motion.div>
                                                
                                            </motion.div>
                                    }
                                    <motion.div
                                        className={`playback-features`}
                                        initial={{opacity: 0}}
                                        animate={{
                                            opacity: videoPlayback ? 1 : 0
                                        }}
                                        exit={{opacity: 0}}
                                    >
                                        {
                                            viewDevice !=='mobile' && viewDevice !=='tablet' &&
                                                <motion.div
                                                    className="sound-option" 
                                                    key="vimeo-sound"
                                                    onClick={(e) => {
                                                        setIsMuted(!isMuted);
                                                        e.stopPropagation();
                                                    }}
                                                    onMouseEnter={() => {setCursorText("")}}
                                                    style={{
                                                        //color: `${isMuted ? "var(--color-highlighted)" : "white"}`
                                                    }}
                                                >
                                                    <motion.div
                                                        className="sound-strikethrough" 
                                                        style={{
                                                            width: `${isMuted ? "100%" : "0%"}`,
                                                            left: `${isMuted ? "0" : ""}`,
                                                            right: `${isMuted ? "" : "0"}`,
                                                        }}
                                                    />
                                                    <span>SOUND</span>
                                                </motion.div>
                                        }
                                        <div 
                                            className="playback-details padding-gutter"
                                            onMouseEnter={() => {setCursorText("")}}
                                        >
                                            <div 
                                                className="playback-title"
                                                style={{
                                                    fontFamily: "var(--font-punchy)",
                                                    textTransform: "uppercase",
                                                    fontWeight: 300
                                            }}
                                            >
                                                <span>{selectedData.title}</span>
                                                <span 
                                                    className="playback-option-dial"
                                                    onMouseEnter={() => setCursorText("")}
                                                    onClick={(e) => handleOverlayClick(e)}
                                                >
                                                    <AnimatePresence mode="wait">
                                                    {
                                                    !isVideoPlaying?
                                                        <motion.svg width="9" height="10" viewBox="0 0 9 11" xmlns="http://www.w3.org/2000/svg" className="play-icon"
                                                            key="play-icon"
                                                            initial="initial"
                                                            animate="animate"
                                                            exit="exit"
                                                            variants={playIconsVariants}
                                                        >
                                                            <path d="M8.25 4.56699C8.58333 4.75944 8.58333 5.24056 8.25 5.43301L0.75 9.76314C0.416667 9.95559 4.85306e-07 9.71503 5.0213e-07 9.33013L8.80682e-07 0.669873C8.97506e-07 0.284973 0.416668 0.0444101 0.750001 0.23686L8.25 4.56699Z" fill="#EFEBE7"></path>
                                                        </motion.svg>
                                                    :
                                                        <motion.svg width="6" height="11" viewBox="0 0 6 11" xmlns="http://www.w3.org/2000/svg" className="pause-icon"
                                                            key="pause-icon"
                                                            initial="initial"
                                                            animate="animate"
                                                            exit="exit"
                                                            variants={playIconsVariants}
                                                        >
                                                            <path d="M0 0.333401C0 0.149268 0.149269 0 0.333402 0H1.66701C1.85114 0 2.00041 0.149269 2.00041 0.333402V9.66866C2.00041 9.85279 1.85114 10.0021 1.66701 10.0021H0.333402C0.149269 10.0021 0 9.85279 0 9.66866V0.333401Z" fill="#EFEBE7"></path>
                                                            <path d="M4 0.333401C4 0.149268 4.14927 0 4.3334 0H5.66701C5.85114 0 6.00041 0.149269 6.00041 0.333402V9.66866C6.00041 9.85279 5.85114 10.0021 5.66701 10.0021H4.3334C4.14927 10.0021 4 9.85279 4 9.66866V0.333401Z" fill="#EFEBE7"></path>
                                                        </motion.svg>
                                                    }
                                                    </AnimatePresence>
                                                </span>
                                            </div>
                                            <div className="playback-progress">
                                                <div
                                                    className="playback-bar__wrapper"
                                                    style={{
                                                        position: "relative",
                                                        width: "100%",
                                                        paddingTop: "4px",
                                                        paddingBottom: "4px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                        pointerEvents: "all"
                                                    }}
                                                    onMouseEnter={() => {setCursorText("")}}
                                                    onClick={(e) => {handleProgressClick(e)}}
                                                >
                                                    <div className="playback-bar"
                                                        style={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            transform: "translateY(-50%)",
                                                            width: "100%",
                                                            height: "2px",
                                                            background: "grey",
                                                            transition: "width 0.25s linear"
                                                        }}></div>
                                                    <div
                                                        className="progress"
                                                        ref={progressBar}
                                                        style={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            transform: "translateY(-50%)",
                                                            width: "0%",
                                                            height: "2px",
                                                            background: "white",
                                                            transition: "width 0.25s linear"
                                                        }}
                                                    />
                                                </div>
                                                <div className="playback-times">
                                                    <div ref={curTimeRef} className="cur-time">0:00</div>
                                                    <div ref={endTimeRef} className="end-time">{minuteRepresentation(duration)}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </motion.div>
                                    <div className="shoot-gradient" />
                                    <motion.div
                                        className="shoot-image"
                                        key="SHOOT-IMG-9021"
                                        initial="initial"
                                        animate={videoPlayback ? "exit" : "animate"}
                                        variants={imgHeroVariants}
                                        onClick={(e) => {
                                            try{
                                                if (document.getElementById("cursor-text").innerText.includes("CLOSE")){
                                                        closeVideoOverlay(e);
                                                        setCursorText("PLAY");
                                                    }
                                            }catch(err){}
                                        }}
                                        onMouseEnter={() => {
                                            setCursorText("CLOSE")
                                        }}
                                    >
                                        <ProgressiveBackgroundImg
                                            placeholderSrc={selectedData.thumbnails.placeholder.toLowerCase()}
                                            src={
                                                viewDevice=='mobile' ? selectedData.thumbnails.laptop.toLowerCase() :
                                                    viewDevice=='tablet' || viewDevice=='laptop' ? selectedData.thumbnails.laptop.toLowerCase() :
                                                        viewDevice=='desktop' ?
                                                            window.innerWidth < 1500 ? selectedData.thumbnails.laptop.toLowerCase() :
                                                                selectedData.thumbnails.desktop.toLowerCase()
                                                        :
                                                            selectedData.thumbnails.desktop.toLowerCase()
                                            }
                                            idx="hero"
                                            delay={850}
                                            forceWindowRescaling={true}
                                            isFirefox={isFirefox}
                                        />
                                    </motion.div>
                                    <motion.div
                                        className="shoot-video"
                                        initial="inital"
                                        animate={videoPlayback ? "animate" : "exit"}
                                        variants={vidHeroVariants}
                                        ref={videoRef}
                                        onClick={(e) => handleLandingClicked(e)} 
                                
                                        onMouseEnter={() => {setCursorText(isVideoPlaying ? "PAUSE" : "PLAY")}}
                                        style={{
                                            height: '90lvh',
                                            width: '100%'
                                        }}
                                    >
                                        <motion.div
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                position:"relative"
                                            }}
                                            initial={{
                                                opacity: 0
                                            }}
                                            animate={{
                                                opacity: 1,
                                                transition:{
                                                    delay: 1
                                                }
                                            }}
                                            exit={{
                                                opacity: 0
                                            }}
                                        >
                                            <div 
                                                className="video-overlay" 
                                                onClick={(e) => handleOverlayClick(e)}
                                                ref={overlayRef}
                                                key="overlay-ref"
                                            />
                                                <Vimeo
                                                    key="player-key"
                                                    video={selectedData.vimeo}
                                                    className="vimeo__wrapper"
                                                    style={{
                                                        position:"absolute",
                                                        top:"0",
                                                        left:"0",
                                                        width:"100%",
                                                        height:"100%"
                                                    }}
                                                    width="100%"
                                                    height= "100%"
                                                    controls={false}
                                                    responsive
                                                    volume={isMuted ? 0 : 1}
                                                    paused={!isVideoPlaying}
                                                    currentTime={10}
                                                    onTimeUpdate={(e) => {handleTimerUpdate(e)}}
                                                    onReady={(player) => handleReady(player)}
                                                    onError={(event)=> {}}
                                                    
                                                />
                                        </motion.div>
                                    </motion.div>
                                    <motion.div 
                                        className={`footer-wrapper`}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        variants={footerVariants}
                                        custom={videoPlayback}
                                        id="shoot-footer"
                                        style={{
                                            height: isOutdatedSafari ? "100%" : "100svh"
                                        }}
                                    >
                                        <div 
                                            className="shoot-footer padding-gutter-sm"
                                        >
                                            <div 
                                                className="shoot-deets"
                                            >
                                                <motion.span ref={shootNumRef} className="shoot-num" variants={footerItemVariants} custom={0.5}>{projectIndex}</motion.span>
                                                <motion.span className="shoot-title" variants={footerItemVariants} custom={1}>{selectedData.title.toUpperCase()}</motion.span>
                                            </div>
                                            <motion.div 
                                                className="shoot-info"
                                                
                                            >
                                                <motion.span
                                                    variants={footerItemVariants}
                                                    custom={2}
                                                    style={{
                                                        display: "inline-block"
                                                    }}
                                                ><span style={{display: "inline-block", fontWeight: "400"}}>SCROLL</span> FOR MORE INFO</motion.span>
                                            </motion.div>
                                            {
                                                viewDevice !== "mobile" && 
                                                <motion.div className="shoot-fullscreen" variants={footerItemVariants} custom={3}>FULLSCREEN <span style={{display: "inline-block", fontWeight: "500"}}>  OFF</span></motion.div>
                                            }
                                        </div>
                                    </motion.div>
                                </div>
                                <div
                                    className="shoot-content__wrapper"
                                    id="content-scroll"                                
                                    key="landingRef"
                                    data-scroll-id="hey"
                                    ref={landingRef}
                                >
                                    <div className="shoot-test"
                                        style={{
                                            height: isOutdatedSafari ? "100%" : "100lvh",
                                            cursor: 'pointer'
                                        }}
                                        ref={openerRef}
                                        onClick={(e) => {
                                            handleImageClick(e);}}
                                        onMouseEnter={() => {setCursorText("PLAY")}}
                                    >


                                    </div>
                                    <div className="shoot-content">
                                        <div className="shoot-content__grad" />
                                        <div className="s-content-wrap padding-gutter-sm">
                                            {
                                                viewDevice !=='mobile' ?
                                                    <div className="info-section__wrapper">
                                                        <div className="info-section credit-section">
                                                            <div className="left-col">
                                                                <div className="info-col-1">
                                                                    <span>TITLE</span>
                                                                    <span>RUNTIME</span>
                                                                    <span>EQPMT</span>
                                                                    <span>DATE</span>
                                                                </div>
                                                                <div className="info-col-2">
                                                                    <ShootInfoHeader 
                                                                        infoName={selectedData.title.toUpperCase()}
                                                                        children={<span>{selectedData.title ? selectedData.title : 'REDACTED'}</span>}
                                                                    >
                                                                        
                                                                    </ShootInfoHeader>
                                                                    {/* <del className="relative-del"> */}
                                                                        <span>
                                                                            {selectedData.runTime ? `${selectedData.runTime[0]} MINUTES ${selectedData.runTime[1] < 10 ? '0' + selectedData.runTime[1] : selectedData.runTime[1]} SECONDS` : 'REDACTED'}
                                                                        </span>
                                                                    {/* </del> */}
                                                                    <span>{selectedData.equipment ? selectedData.equipment.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{selectedData.date ? selectedData.date.toUpperCase() : 'REDACTED'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="center-col">
                                                                <div className="info-col-1">
                                                                    <span>DIRECTOR</span>
                                                                    <span>DOP</span>
                                                                    <span>EDITOR</span>
                                                                </div>
                                                                <div className="info-col-2">
                                                                    <span>{selectedData.director ? selectedData.director.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{selectedData.dop ? selectedData.dop.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{selectedData.editor ? selectedData.editor.toUpperCase() : 'REDACTED'}</span>
                                                                </div>
                                                            </div>
                                                            <div className="right-col">
                                                            <div className="info-col-1">
                                                                    <span>PRODUCER</span>
                                                                    <span>GRADE</span>
                                                                    <span>TYPE</span>
                                                                </div>
                                                                <div className="info-col-2">
                                                                    <span>{selectedData.producer.toUpperCase()}</span>
                                                                    <span>{selectedData.grade.toUpperCase()}</span>
                                                                    <span>{workTags[selectedData.tags[0]-1].tagName}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="info-section aux-section">
                                                            <div className="aux-vert">
                                                            <span> {selectedData.location.toLocaleUpperCase()}</span>
                                                                <span id="long-lat">{selectedData.coords[0].toLocaleUpperCase() + " " + selectedData.coords[1].toLocaleUpperCase()}</span>
                                                            </div>
                                                            <div className="aux-vert">
                                                                <div className='info-item-wrap'><span>SHOT ON:</span>  {selectedData.shotOn.toUpperCase()}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="info-section__wrapper">
                                                        <div className="shoot-section-header"><span>CREDIT</span></div>
                                                        <div className="info-section credit-section">
                                                            
                                                                <div className="info-col-1">
                                                                    <span>TITLE</span>
                                                                    <span>RUNTIME</span>
                                                                    <span>EQPMT</span>
                                                                    <span>DATE</span>
                                                                    <span>DIRECTOR</span>
                                                                    <span>DOP</span>
                                                                    <span>EDITOR</span>
                                                                    <span>PRODUCER</span>
                                                                    <span>GRADE</span>
                                                                    <span>TYPE</span>
                                                                </div>
                                                                <div className="info-col-2">
                                                                    <span>{selectedData.title ? selectedData.title.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>
                                                                        {selectedData.runTime ? `${selectedData.runTime[0]} MINUTES ${selectedData.runTime[1] < 10 ? '0' + selectedData.runTime[1] : selectedData.runTime[1]} SECONDS` : 'REDACTED'}
                                                                    </span>
                                                                    <span>{selectedData.equipment ? selectedData.equipment.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{selectedData.date ? selectedData.date.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{selectedData.director ? selectedData.director.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{selectedData.dop ? selectedData.dop.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{selectedData.editor? selectedData.editor.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{selectedData.producer ? selectedData.producer.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{selectedData.grade ? selectedData.grade.toUpperCase() : 'REDACTED'}</span>
                                                                    <span>{workTags[selectedData.tags[0]-1].tagName ? workTags[selectedData.tags[0]-1].tagName  : 'REDACTED'}</span>
                                                                </div>
                                                        </div>

                                                        
                                                        <div className="info-section aux-section as-2">
                                                            <div className="aux-vert">
                                                                <span> {selectedData.location.toLocaleUpperCase()}</span>
                                                                <span id="long-lat">{selectedData.coords[0].toLocaleUpperCase() + " " + selectedData.coords[1].toLocaleUpperCase()}</span>
                                                            </div>
                                                            <div className="aux-vert">
                                                                <div className='info-item-wrap'><span>SHOT ON:</span> {selectedData.shotOn.toUpperCase()}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            <div className="shoot-section-wrapper">
                                                <div 
                                                    className="stills__wrapper"
                                                >
                                                    {
                                                        selectedData.stills.map((still, stillIdx) => {
                                                            return (
                                                                <div className="still-item" style={{overflow:"hidden", borderRadius: "3px"}}>
                                                                    <ProgressiveImageItem
                                                                        wrapperName={"still__wrapper"}
                                                                        key={"progressive-shoot-"+stillIdx}
                                                                        placeholderSrc={still.placeholder.toLowerCase()}
                                                                        src={window.innerWidth <= 600 ? still.backgroundImage.toLowerCase() : 
                                                                                window.innerWidth < 1500 ? still.backgroundImage.replace("/mobile/", "/laptop/").toLowerCase() :
                                                                                    still.backgroundImage.replace("/mobile/", "/desktop/").toLowerCase()
                                                                            }
                                                                        idx={stillIdx}
                                                                        isFirefox={isFirefox}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div                                             
                                                    className="next-prev-section"
                                                >
                                                    <div className="next-prev-content__wrapper">
                                                        <div className="prev-next-option">PREV</div>
                                                        <Link 
                                                            to={"/projects/"+sanitiser(neighbourProj.prev.link)}
                                                            style={{
                                                                height:"100%",
                                                                aspectRatio: viewDevice !=='mobile' ? '1.75/1' : '2.5/1'
                                                            }}
                                                        >
                                                            <ProgressiveImageItem 
                                                                wrapperName={"next__wrapper"}
                                                                key={"shoot-prev"}
                                                                placeholderSrc={neighbourProj.prev.placeholder.toLowerCase()}
                                                                src={neighbourProj.prev.thumbnail.toLowerCase()}
                                                                idx={0}
                                                                forceAspectRatio={viewDevice !=='mobile' ? 1.5 : 2.5}
                                                                isFirefox={isFirefox}
                                                            />
                                                        </Link>
                                                        <div className="prev-next-title">
                                                            <span id="st-f">{neighbourProj.prev.title.replace("_", " ").toLowerCase()}</span>
                                                            <span 
                                                                className="st-num"
                                                            > {neighbourProj.prev.idx < 9 ? '00' + (neighbourProj.prev.idx + 1) : '0' + (neighbourProj.prev.idx + 1)}</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="next-prev-content__wrapper">
                                                        <div className="prev-next-option next-option">NEXT</div>
                                                            <Link 
                                                                to={"/projects/"+sanitiser(neighbourProj.next.link)}
                                                                relative="relative"
                                                                style={{
                                                                    height:"100%",
                                                                    aspectRatio: viewDevice !=='mobile' ? '1.75/1' : '2.5/1'
                                                                }}
                                                                onMouseEnter={(e)=>{handleNextPrevEnter(e)}}
                                                                onMouseLeave={(e)=>{handleNextPrevLeave(e)}}
                                                            >
                                                            <ProgressiveImageItem 
                                                                wrapperName={"next__wrapper"}
                                                                key={"shoot-prev"}
                                                                placeholderSrc={neighbourProj.next.placeholder.toLowerCase()}
                                                                src={neighbourProj.next.thumbnail.toLowerCase()}
                                                                idx={0}
                                                                forceAspectRatio={viewDevice !=='mobile' ? 1.5 : 2.5}
                                                                isFirefox={isFirefox}
                                                            />
                                                            </Link>
                                                        <div className="prev-next-title">
                                                            <span className="st-num">{neighbourProj.next.idx < 9 ? '00' + (neighbourProj.next.idx + 1) : '0' + (neighbourProj.next.idx + 1)}</span>
                                                            <span id="st-l">{neighbourProj.next.title.replace("_", " ").toLowerCase()}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                }
            </ErrorBoundary>
        )
    } catch (err){
        console.log(err)
    }

}

export default Shoot;