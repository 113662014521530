import React from "react";
import { Link } from 'react-router-dom';
import ProgressiveImageItem from "../ProgressiveImageItem/ProgressiveImageItem";
import { sanitiser } from "../../globals/helpers";
import { cursorChange } from "../../globals/helpers";
import { motion } from "framer-motion"
import { deviceType } from "detect-it";

function IndexItem({placeholderSrc, backgroundSrc, title, isMobile, index}){
    let mobileMode = deviceType == "mouseOnly" ? 0 : 1
    return(
        <motion.div 
            initial={{
                transform: "translateZ("+( mobileMode ? -550 * (index+1) - 500 : -825 * (index+1) - 500)+"px)",
                opacity: 0
            }}
            animate={{
                transform: "translateZ("+(mobileMode ? -550 * (index+1) : -825 * (index+1))+"px)",
                opacity: index == 0 ? 1 : mobileMode ? (index+1) * 550 <= 3000 ? 1 - (((index+1) * 550)/3000) : 0 : index == 0 ? 1 : (index+1) * 825 <= 3000 ? 1 - (((index+1) * 825)/3000) : 0,
                transition: {
                    duration: 0.5
                }
            }}
            className={`ddd-wrapper index-item-${index % 4 <= 1 ? index % 4==0 ? "top index-item-right" : "top index-item-left" : index % 4==2 ? "bottom index-item-right" : "bottom index-item-left" }`} 
            style={{
                filter: index == 0 ? "blur(6.5px)" : "blur(40px)",
            }}
        >
            <Link to={"/projects/"+sanitiser(title)}>
                <ProgressiveImageItem
                    wrapperName={"still__wrapper"}
                    key={"progressive-index-"+index}
                    placeholderSrc={placeholderSrc}
                    src={backgroundSrc}
                    idx={index}
                    forceAspectRatio={1.55}
                />
            </Link>
            
            <div style={{position: 'absolute', top: '0', pointerEvents:  isMobile ? 'none' : ""}}
                onMouseEnter={(e) => {
                    cursorChange(true, 2)
                }}
                onMouseLeave={(e) => {cursorChange(false)}}
            >
                <Link to={"/projects/"+sanitiser(title)}>
                    <div className="z-title__wrapper" style={isMobile ? {pointerEvents: 'none'} : {}}>
                        {!isMobile ?
                            <>
                                <span className="z-thumb">{title}</span>
                                <span className="z-thumb">{title}</span>
                            </>
                            :
                                <span className="z-thumb">{title}</span>
                        }
                    </div>
                </Link>
            </div>
        </motion.div>
    )
}

export default IndexItem;