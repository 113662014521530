
import React, {useState} from "react";
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion"


const placeholderVariants ={
    initial: {
      opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: [0.33, 1, 0.68, 1]
        }
    }
}

const fullImgVariants ={
    initial: {
      opacity: 0,
      filter: 'blur(15px)',
      transform: 'scale(1.05)',
    },
    animate: {
        opacity: 1,
        filter: 'blur(0px)',
        transform: 'scale(1)',
        transition: {
            duration: 0.65,
            ease: [0.33, 1, 0.68, 1]
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.65,
            ease: [0.33, 1, 0.68, 1]
        }
    }
}


const ProgressiveImageItem = ({ placeholderSrc, src, idx, forceAspectRatio = null, isFirefox = false}) => {
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '0px 0px'
      });
    const [isLoaded, setIsLoaded] = useState(false)
    const [aspectRatio, setApectRatio] = useState(16/9);


    function handleImageLoad(e){

        let imgWidth = e.target.width;
        let imgHeight = e.target.height;
        if ((idx + 1) % 3==0){
            setApectRatio(Math.max(1.65, imgWidth/imgHeight));
        } 
        if (!forceAspectRatio){
            if ((imgWidth/imgHeight) > (16/9)){
                e.target.classList.add("full-height");
            } else{
                e.target.classList.add("full-width");
            }
        } else{
            if ((imgWidth/imgHeight) > forceAspectRatio){
                e.target.classList.add("full-height");
            } else{
                e.target.classList.add("full-width");
            }
        }
        setIsLoaded(true)
    }

    return (
        <div ref={ref}
            className="shoot-tile__wrapper"
            style={{
                aspectRatio: !forceAspectRatio ? aspectRatio : forceAspectRatio,
                height: !forceAspectRatio ? null : "100%"
            
            }}
        >
            <motion.img 
                initial="intial"
                animate="animate"
                exit="exit"
                variants={ placeholderVariants}
                style={{
                    position: 'absolute',
                    transform: 'scale(1.05)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    width: '100%',
                    height: '100%',
                    backgroundRepeat: 'no-repeat',
                    transition: '0.5s -webkit-filter linear,0.5s transform linear',
                    clipPath: 'inset(0.8px)',
                    opacity: '1',
                    filter: 'none',
                    transform: 'none',
                    backgroundImage: 'url('.concat(placeholderSrc.toLowerCase(),')')
                }}
            />
            {
                inView &&
                <motion.img
                    className="full-shoot-tile"
                    initial="intial"
                    animate={isLoaded ? "animate" : "initial"}
                    exit="exit"
                    variants={fullImgVariants}
                    onLoad={(e) => handleImageLoad(e)}
                    style={{
                        transform: 'scale(1.05)',
                        position: 'absolute',
                        opacity: '0',
                        transformOrigin: 'center'
                    }}
                    src={src.toLowerCase()}
                />
            }
        </div>
    );
  };
  export default ProgressiveImageItem;