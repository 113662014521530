import React, {useEffect, useState} from "react";
import { AnimatePresence, motion } from "framer-motion";

function ResponsiveText({text, propVariants}){

    

    return (
        <>
            <span style={{overflow: 'hidden', display:'flex'}}>
            {
                
                text.toString().split("").map((tchar, tidx) => {
                    return(
                        <motion.span 
                            key={text+"paused-overlay-"+tchar+"-"+tidx}
                            variants={propVariants}
                        >
                            {tchar}
                        </motion.span>
                    )
                })
            }
            </span>
        </>
    );

}

export default ResponsiveText;