import React, {useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import LocomotiveScroll from "locomotive-scroll";
import "./informationNew.css";
import { getDevice } from "../../globals/helpers";
import ProgressiveBackgroundImg from "../../components/ProgressiveBackgroundImg/ProgressiveBackgroundImg";
import { cursorChange } from "../../globals/helpers";
import { motion } from "framer-motion"
import { updateTime } from "../../globals/helpers";
import { gridItems } from "../../globals/data";

function InformationNew(){
    const projTitleRef = useRef(null);
    const [londonTime, setLondonTime] = useState(null);
    let curDate = null;
    const speedIdx = [-1, -2, -2, -1, -2];
    const [isFirefox, setIsFirefox] = useState(false);
    
    useEffect(() => {
        let nVer;
        let nAgt;
        let browserName;
        let fullVersion;
        let majorVersion;
        let verOffset;
        nVer = navigator.appVersion;
        nAgt = navigator.userAgent;
        browserName  = navigator.appName;
        fullVersion  = ''+parseFloat(navigator.appVersion); 
        majorVersion = parseInt(navigator.appVersion,10);

        if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset+8);
            setIsFirefox(true);
        }

        document.documentElement.style.setProperty("overflow-y", "");
        try{
            window.scrollTo(0,0);
        } catch{}
        try{
        updateTime(setLondonTime)} catch{}
        let timeInterval = setInterval(function() {
           updateTime(setLondonTime, curDate)
        }, 5000)

        let scroll;
        let testInterval = setInterval(() => {
            let scrollTargEle;
            try{
                scrollTargEle = document.getElementById('information-page');
            } catch{}
            if (getDevice() !== "mobile"){
                scroll = new LocomotiveScroll({
                    el: scrollTargEle,
                    direction: "vertical",
                    gestureDirection: "vertical",
                    smooth: true,
                    lerp: 0.05,
                    touchMultiplier: 4,
                    smartphone: {
                        smooth: true,
                        direction: 'vertical'
                    },
                    tablet: {
                        smooth: true,
                        direction: 'vertical',
            
                    },
                    reloadOnContextChange: true
                });
                try{
                    scroll.destroy();
                } catch{}
                let passDev = getDevice();
                setTimeout(function () {
                    try{scroll.init()} catch{}
                    if(scroll){
                        let notAdjustingScroll = true;
                        try{
                            scroll.on('scroll', (args) => {
                                if (args.scroll.y==args.limit.y && notAdjustingScroll){
                                    notAdjustingScroll = false;
                                    setTimeout(() => {
                                    notAdjustingScroll = true;
                                    }, 250)
                                    scroll.scrollTo(
                                    'bottom', {
                                        'offset': -2,
                                        'duration': 0.25,
                                    }
                                    )
                                }
                            });
                        } catch{}
                    }
                }, 150);
                try{
                    clearInterval(testInterval)
                } catch{}
            }else{
                try{
                    clearInterval(testInterval)
                } catch{}
            }
        }, 200)
        return () => {
            try{
                if(scroll)
                    scroll.destroy();
            }
            catch(err){
            }
            try{
                clearInterval(testInterval)
            }catch(err){
            }
            try{
                clearInterval(timeInterval);
            }catch{}
          };
    },[])

    const infoStills = [
        {
            backgroundImage: "../Media/main/datway/stills/mobile/Still4.webp",
            placeholder: "../Media/main/datway/stills/placeholder/Still4.webp"
        },
        {
            backgroundImage: "../Media/main/datway/stills/mobile/Still1.webp",
            placeholder: "../Media/main/datway/stills/placeholder/Still1.webp"
        },
        {
            backgroundImage: "../Media/main/linkinends/stills/mobile/Still3.webp",
            placeholder: "../Media/main/linkinends/stills/placeholder/Still3.webp"
        },
        {
            backgroundImage: "../Media/main/diggadkindnessforweakness/stills/mobile/Still4.webp",
            placeholder: "../Media/main/diggadkindnessforweakness/stills/placeholder/Still4.webp"
        },
        {
            backgroundImage: "../Media/main/tiptoes/stills/mobile/Still4.webp",
            placeholder: "../Media/main/tiptoes/stills/placeholder/Still4.webp"
        }   
    ]

    const infoHeaderVariants ={
        initial:{
            transform: "translate3d(0, 100%, 0)"
        },
        animate: i => ({
            transform: "translate3d(0, 0%, 0)",
            transition:{
                delay: 0.1*i,
                ease: [0.33, 1, 0.68, 1],
                duration: 0.55
            }
        }),
        exit: i=> ({
            transform: "translate3d(0, 100%, 0)",
            transition:{
                delay: 0.1*i,
                ease: [0.32, 0, 0.67, 0],
                duration: 0.65
            }
        })
    }

    const infoSubHeaderVariants ={
        initial:{
            transform: "translate3d(0, 100%, 0)"
        },
        animate: i => ({
            transform: "translate3d(0, 0%, 0)",
            transition:{
                delay: 0.1*i,
                ease: [0.33, 1, 0.68, 1],
                duration: 0.75
            }
        }),
        exit: i => ({
            transform: "translate3d(0, 100%, 0)",
            transition:{
                delay: 0.1*i,
                ease: [0.32, 0, 0.67, 0],
                duration: 0.35
            }
        })
    }

    const bgFigureVariants = {
        initial:{
            opacity: 0,
        },
        animate: i => ({
            opacity: 1,
            transition:{
                delay: 0.3 + 0.15 * i,
                ease: [0.33, 1, 0.68, 1],
                duration: 1.5
            }
        }),
        exit: i => ({
            opacity: 0,
            transition:{
                delay: 0.25 + 0.15 * i,
                ease: [0.33, 1, 0.68, 1],
                duration: 0.75
            }
        })
    }

    const textSecVariants = {
        initial:{
            opacity: 0,
        },
        animate: i => ({
            opacity: 1,
            transition:{
                delay: 0.3 + 0.15 * i,
                ease: [0.33, 1, 0.68, 1],
                duration: 1.5
            }
        }),
        exit: i => ({
            opacity: 0,
            transition:{
                delay: 0.3 + 0.15 * i,
                ease: [0.33, 1, 0.68, 1],
                duration: 0.75
            }
        })
    }

    return(
        <>
            <div id="information-page" className="info-page__wrapper" data-scroll-container>
                <div className="info-content__wrapper padding-gutter-sm"  data-scroll data-scroll-section>
                    <div className="info__upper-tile">
                        <div className="info-header__wrapper">
                            {/* <div className="header-row header-row-1">
                                <motion.span 
                                    className="ls-sm stretch"
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    custom={0}
                                    variants={infoHeaderVariants}
                                >GET
                                </motion.span>
                                <motion.span 
                                    className="italic fancy-font" style={{paddingLeft: getDevice()=="mobile" ? "4rem" : "8rem"}}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    custom={1}
                                    variants={infoHeaderVariants}
                                >
                                    in
                                </motion.span>
                            </div> */}
                            <div className="header-row header-row-2">
                                    <motion.span className="ls-sm stretch"
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        custom={2}
                                        variants={infoHeaderVariants}
                                    >CONTACT</motion.span>
                            </div>
                            {/* <div className="header-row header-row-3">
                                <motion.span className="italic fancy-font" style={{paddingLeft: getDevice()=="mobile" ? "" : "1.5rem"}}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    custom={3}
                                    variants={infoHeaderVariants}
                                >
                                    with
                                </motion.span>
                                <motion.span className="ls-sm stretch"
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    custom={4}
                                    variants={infoHeaderVariants}
                                >
                                    ME
                                </motion.span>
                            </div> */}
                        </div>
                        <div className="work-details-wrapper">
                            <div className="ov-hid">
                                <motion.span
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    custom={5}
                                    variants={infoSubHeaderVariants}
                                    style={{display: "inline-block"}}
                                >AVAILABLE FOR WORK</motion.span>
                            </div>
                            <div className="ov-hid">
                                <motion.span
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    custom={5.5}
                                    variants={infoSubHeaderVariants}
                                    style={{display: "inline-block"}}
                                >CONTACT FOR FURTHER DETAILS</motion.span>
                            </div>
                        </div>
                    </div>
                    <motion.div className="page-splitter pg-sp-1"
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        custom={0}
                        variants={textSecVariants}
                    >
                        <div className="info__left-col">
                            <div className="info-pg-section info-contact__sec">
                                <div className="info-sec__table">
                                    <div className="info__var-col">
                                        <div className="sub-sec-varname">
                                            <span>Working Across</span>
                                        </div>
                                    </div>
                                    <div className="info-sec__wrapper">
                                        <div className="info__val-col">
                                            <div className="info__val-col-1">
                                                <div className="sub-sec-value">
                                                    <span><span className="info-tag-styler fw-700 italic"></span>Music Promos</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span><span className="info-tag-styler fw-700 italic"></span>Commercial</span>
                                                </div>
                                            </div>
                                            <div className="info__val-col-2">
                                                <div className="sub-sec-value">
                                                    <span><span className="info-tag-styler fw-700 italic"></span>Short Form</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span><span style={{color: "var(--kodak-dark)" }}className="info-tag-styler fw-700 italic">Your Next Project</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                getDevice() !=="mobile" &&
                                <div className="info-location__wrapper" style={{fontWeight: 600}}>
                                    <div>LONDON, UNITED KINGDOM</div>
                                    <div>51.5072° N, 0.1276° W</div>
                                </div>
                            }
                        </div>
                        { getDevice() !=="mobile" &&
                        <div className="info__right-col">
                            <div className="contact-details__wrapper">
                                <div className="contact-item"
                                    onMouseEnter={() => {cursorChange(true)}}
                                    onMouseLeave={() => {cursorChange(false)}}
                                >
                                    <a href="https://www.instagram.com/mrjdop" target="_blank" rel="noreferrer" >
                                        <span>INSTA</span>
                                    </a>
                                </div>
                                <div className="contact-item mg-tp-sm"
                                    onMouseEnter={() => {cursorChange(true)}}
                                    onMouseLeave={() => {cursorChange(false)}}
                                >
                                    <a href="https://vimeo.com/user41361139" target="_blank" rel="noreferrer" >
                                        <span>VIMEO</span>
                                    </a>
                                </div>
                                <div className="contact-item mg-tp-sm"
                                    onMouseEnter={() => {cursorChange(true)}}
                                    onMouseLeave={() => {cursorChange(false)}}
                                >
                                    <a href="mailto:michaelrichardjohnson@hotmail.com" target="_blank" rel="noreferrer" >
                                        <span>EMAIL</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        }
                    </motion.div>
                    
                    <motion.div
                        className="page-splitter pg-sp-2"
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        custom={1}
                        variants={textSecVariants}
                    >
                        <div className="info__left-col">
                            <div className="info-about__wrapper">
                                <span className="info-cont-header ">About</span>
                                <div className="info-about mg-tp-md">
                                    <span>
                                        Based in sunny London, Michael Richard Johnson is a Director of Photography & owner/operator, lover of Espresso and has been described as a “4:3 Merchant”. <br/><br/>
                                        Mike has a sleek kit and prides himself on being one of the most nimble & creative Director of Photography's about.<br/><br/>
                                        Although based in London, Mike is open for international work & has delivered his expertise & skillset on projects from Los Angeles to Nigeria. <br/><br/>
                                        View the equipment section for further insight into what Mike can offer, and get in touch if you have any further inquiries about Mike and his work. 
                                    </span>
                                    
                                </div>
                            </div>
                        </div>
                        <div id="second-right-col" className="info__right-col">
                            {
                                getDevice()=="mobile" ?
                                    <>
                                        <div className="contact-details__wrapper">
                                            <div className="contact-item"
                                                onMouseEnter={() => {cursorChange(true)}}
                                                onMouseLeave={() => {cursorChange(false)}}
                                            >
                                                <a href="https://www.instagram.com/mrjdop" target="_blank" rel="noreferrer" >
                                                    <span>INSTA</span>
                                                </a>
                                            </div>
                                            <div className="contact-item mg-tp-sm"
                                                onMouseEnter={() => {cursorChange(true)}}
                                                onMouseLeave={() => {cursorChange(false)}}
                                            >
                                                <a href="https://vimeo.com/user41361139" target="_blank" rel="noreferrer" >
                                                    <span>VIMEO</span>
                                                </a>
                                            </div>
                                            <div className="contact-item mg-tp-sm"
                                                onMouseEnter={() => {cursorChange(true)}}
                                                onMouseLeave={() => {cursorChange(false)}}
                                            >
                                                <a href="mailto:michaelrichardjohnson@hotmail.com" target="_blank" rel="noreferrer" >
                                                    <span>EMAIL</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="info-location__wrapper" style={{fontWeight: 500}}>
                                            <span>LONDON, UNITED KINGDOM</span>
                                            <span>51.5072° N, 0.1276° W</span>
                                        </div>
                                    </>
                                :
                                    <div className="info-gear__wrapper">
                                        <span className="info-cont-header">Equipment</span>
                                        <div className="info-gear-cols mg-tp-md">
                                            <div className="info__var-col">
                                                <div className="sub-sec-value">
                                                    <span>camera</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>lenses</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>monitoring</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>support</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>

                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>mattebox</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>filters</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>&nbsp;</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>other</span>
                                                </div>
                                            </div>
                                            <div className="info__val-col-1">
                                                <div className="sub-sec-value">
                                                    <span>Alexa Mini Package</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>Cooke s4 18/25/32/50/75mm</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Tokina T2.8 11-20mm</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>Indie5 hd5</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>HD 1303 13"</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>JVC 21" client monitor </span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Teradek Bolt 6 1TX 2RX</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Small HD Cine7 w/ Bolt6 RX</span>
                                                </div>

                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>MoviPro® Ignite Digi Package</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Flowcine G Link 1.5</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>RC cart for MoviPro®</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Hague Car Mount</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Satchler V20 Tripod</span>
                                                </div>

                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>Tilta mattebox</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>Fire crest 0.6/0.9/1.2 nd</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Black satin 1/8</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Optical flat</span>
                                                </div>

                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>Eartec Uktralite Headset Package x5</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-sm">
                                                    <span>Adicam standard+ w/ custom parts</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Mac M1 Pro w/ Resolve & Shotput</span>
                                                </div>
                                                <div className="sub-sec-value mg-tp-xs">
                                                    <span>Wheels on & VW transporter</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                            }
                        </div>
                    </motion.div>

                    <motion.div 
                        className="info__page-ender"
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        custom={2}
                        variants={textSecVariants}
                    >
                        <div className="pre-ender">
                            <span>WANT TO SEE MORE?</span>
                        </div>
                        <div 
                            id="more-projects__wrapper" data-scroll
                        >
                            <div 
                                className="more-projects__title"
                                ref={projTitleRef}
                            >
                                {"VIEW PROJECTS".split('').map((item, idx) => {
                                    return(<span>{item}</span>)
                                })
                                }
                            </div>
                            <div className="underline__wrapper">
                                <div id="underline-ele" />
                            </div>
                                <Link to="/index">
                                    <div className="image-control__wrapper" 
                                        data-scroll
                                        onMouseEnter={(e) => {
                                                cursorChange(true, 1);
                                                
                                                let underTarg = document.getElementById("underline-ele")
                                                underTarg.style.width = "100%";
                                                underTarg.style.right = "";
                                                underTarg.style.left = "0";
                                                let targ = e.target;
                                                if (!targ.classList.contains("full-thumb")){
                                                    if(targ.nodeName=='IMG'){
                                                        targ = targ.parentElement.getElementsByClassName("full-thumb")[0];
                                                    }
                                                    else{
                                                        targ = targ.getElementsByClassName("full-thumb")[0];  
                                                    }
                                                }
                                                targ.style.transition = "transform 0.4s ease"
                                                targ.style.transform = "scale(1.1)";
                                            }
                                        }
                                        onMouseLeave={()=>{
                                            cursorChange(false)
                                            
                                            let underTarg = document.getElementById("underline-ele")
                                            underTarg.style.width = "0%";
                                            underTarg.style.right = "0";
                                            underTarg.style.left = "";
                                            let targ = document.getElementsByClassName("image-control__wrapper")[0];
                                            targ = targ.getElementsByClassName("full-thumb")[0];
                                            targ.style.transform = "scale(1.0)";
                                        }}
                                    >
                                        <ProgressiveBackgroundImg 
                                            placeholderSrc={gridItems[0].thumbnails.placeholder}
                                            src={gridItems[0].thumbnails.desktop}
                                            forceFullWidth={true}
                                            isFirefox={isFirefox}
                                        />
                                    </div>
                                </Link>
                            <div className="image__footer-info fancy-font">
                                <span>00</span>
                                <span>{gridItems[0].title}</span>
                            </div>
                        </div>
                    </motion.div>
                </div>
                {getDevice() !=="mobile" &&
                    <motion.div 
                        className="info-bg__wrapper padding-gutter-sm"
                        data-scroll data-scroll-section
           
                    >
                        {
                            infoStills.map((item, idx) => {
                                return(
                                    <motion.div 
                                        className="bg-figure" data-scroll  data-scroll-speed={speedIdx[idx]}
                                        initial="intial"
                                        animate="animate"
                                        exit="exit"
                                        variants={bgFigureVariants}
                                        custom={idx}    
                                    >
                                        <ProgressiveBackgroundImg 
                                            placeholderSrc={item.placeholder}
                                            src={item.backgroundImage}
                                            isFirefox={isFirefox}
                                        />
                                    </motion.div>
                                )
                            })
                        }
                    </motion.div>
                }
                <motion.div
                    className="information-page__footer padding-gutter-sm"
                    style={{zIndex: 10}}
                    initial="intial"
                    animate="animate"
                    exit="exit"
                    variants={bgFigureVariants}
                    custom={0} 
                >
                    <div className="timezone__wraper" key={'london-timer-'+londonTime}>
                        <span>LON:&nbsp;&nbsp;<span style={{display: "inline-block", fontWeight: "600", letterSpacing: "0px"}}>{londonTime}</span></span>
                    </div>
                    
                    <div className="rights-box">
                        <a href="https://www.instagram.com/mrjdop" target="_blank" rel="noreferrer" >
                            <span
                                onMouseEnter={() => {cursorChange(true)}}
                                onMouseLeave={() => {cursorChange(false)}}
                            >IG</span>
                        </a>
                        <a href="https://vimeo.com/user41361139" target="_blank" rel="noreferrer" >
                            <span 
                                onMouseEnter={() => {cursorChange(true)}}
                                onMouseLeave={() => {cursorChange(false)}}
                            >VIM</span>
                        </a>
                        {
                        
                            <a href="mailto:michaelrichardjohnson@hotmail.com" target="_blank" rel="noreferrer" >
                                <span 
                                    onMouseEnter={() => {cursorChange(true)}}
                                    onMouseLeave={() => {cursorChange(false)}}
                                >EML</span>
                            </a>
                        }
                        <span 
                            onMouseEnter={() => {cursorChange(true)}}
                            onMouseLeave={() => {cursorChange(false)}}
                        >©2024</span>
                    </div>
                </motion.div>
            </div>
        </>
    )
}

export default InformationNew;