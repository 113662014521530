import React from "react";
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import {AnimatePresence, motion, LayoutGroup} from "framer-motion";
import Homepage from './pages/homepage/Homepage';
import Index from "./pages/Index/Index";
import Information from "./pages/Information/Information";
import Shoot from "./pages/Shoot/Shoot";
import Fallback from "./pages/Fallback/Fallback";
import InformationNew from "./pages/InformationNew/InformationNew";
import Showreel from "./pages/Showreel/Showreel";
import { gridItems } from "./globals/data";
import { sanitiser } from "./globals/helpers";
const AnimatedRoutes = ({controlAuxCursor, setCursorText, setIsLoading, isOutdatedSafari, setIsHomeScreen}) =>{
    const location = useLocation();
    return (
            <AnimatePresence initial="false" mode="wait">
                <Routes location={location} key={location.pathname}>  
                    <Route exact path="/" element={<Homepage setCursorText={setCursorText} controlAuxCursor={controlAuxCursor} setIsLoading={setIsLoading} setIsHomeScreen={setIsHomeScreen}/>}/>
                    <Route exact path="/index" element={<Index controlAuxCursor={controlAuxCursor} setIsLoading={setIsLoading} isOutdatedSafari={isOutdatedSafari}/>} />
                    {/* <Route exact path="/showreel" element={<Showreel setCursorText={setCursorText}/>} /> */}
                    <Route exact path="/information" element={<InformationNew controlAuxCursor={controlAuxCursor} setIsLoading={setIsLoading}/>} />
                    {/* <Route exact path="/projects/:projID" element={<Shoot setCursorText={setCursorText} setIsLoading={setIsLoading}/>} /> */}
                    {
                        gridItems.map((projectDict, projIdx) => {
                            return(
                                <Route path={"/projects/"+sanitiser(String(projectDict.title).replace(" ", ""))}  element={<Shoot projID={String(projectDict.title).replace(/\s/g,'')} setCursorText={setCursorText} setIsLoading={setIsLoading}/>} />
                            )
                        })
                    }
                    <Route path='*' element={<Fallback setIsLoading={setIsLoading} />}/>
                </Routes>
            </AnimatePresence>
    );
}

export default AnimatedRoutes;