import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion"
import "./navbar.css"
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { cursorChange } from "../../globals/helpers";

function Navbar({ menuActive, handleMenuClick, setCursorText, isHomeScreen}) {
    const callSign = "Michael_R._Johnson";
    const callSignMob = "M.R.J.";
    const [isMobile, setIsMobile] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const location = useLocation();

    const indexOptionRef = useRef(null);
    const homeOptionRef = useRef(null);
    const indexTwoOptionRef = useRef(null);
    const contactOptionRef = useRef(null);
    const showreelOptionRef = useRef(null);

    const callSignContainerVariants = {
        hidden: {},
        show: {
            transition: {
                staggerChildren: 0.03
            }
        },
        exit: {
            transition: {
                staggerChildren: 0.03
            }
        }
    }
    const callSignVariants = {
        hidden: {
            opacity: 0
        },
        show: {
            opacity: 1,
            transition: {
                duration: 0.3,
                ease: "easeOut"
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.3,
                ease: "easeOut"
            }
        }
    }

    const menuToggleVariant = {
        initial: (i) => ({
            borderBottom: i[1] ? "1px solid rgba(255,255,255,0)" : "",
            transition: {
                staggerChildren: 0.02
            }
        }),
        animate: (i) => ({
            borderBottom:  i[1] ? i[0] ? "1px solid rgba(255,255,255,1)" : "1px solid rgba(255,255,255,0)" : "",
            transition: {
                staggerChildren: 0.02
            }
        }),
        exit: (i) => ({
            borderBottom: i[1] ?"1px solid rgba(255,255,255,0)" : "",
            transition: {
                staggerChildren: 0.02
            }
        })
    }

    const lowerWrapperVariants = {
        initial: {
        },
        animate: {
            transition: {
                staggerChildren: 0.1
            }
        },
        exit: {
            transition: {
                staggerChildren: 0.1
            }
        },
    }

    const lowerVariants = {
        hidden: {
            opacity: 0
        },
        show: {
            opacity: 1,
            transition: {
                duration: 0.3,
                ease: "easeOut"
            }
        },
        exit: {
            opacity: 0
        }
    }

    const menuToggleCharVariant = {
        initial: {
            transform: "translate3d(0px, 100%, 0px)"
        },
        animate: i => ({
            transform: "translate3d(0px, 0%, 0px)",
            transition: {
                duration: 0.55,
                ease: [0.33, 1, 0.68, 1]
            }
        }),
        exit: {
            transform: "translate3d(0px, -100%, 0px)",
            transition: {
                duration: 0.25,
                ease: [0.33, 1, 0.68, 1]
            }

        }
    }

    function handleResize(e = false) {
        let width;
        let height;
        if (e) {
            width = e.target.innerWidth;
            height = e.target.innerHeight;
        } else {
            width = window.innerWidth;
        }
        if(width){
            if (width < 700) {
                setIsMobile(2);
            } else if (width < 860) {
                setIsMobile(1)
            }
            else {
                setIsMobile(0)
            }
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', (e) => handleResize(e), true);
    }, [])



    useEffect(() => {
        let cur_path = window.location.href.toLowerCase();
        let new_path = null;
        
        if(cur_path.includes("index"))
            new_path = "index"
        else if(cur_path.includes("information"))
            new_path = "information"
        else if(cur_path.includes("showreel"))
            new_path = "showreel"
        else if (window.location.pathname=='/')
            new_path = "home"
        
        
        if(isMobile){
            try{
                if(isMobile==2 && new_path=="index"){
                    new_path = "index-2"
                }
                let allTiles = document.getElementsByClassName("menu-option");
                for (let i = 0; i < allTiles.length; i++){
                    let tmpChild = allTiles[i];
                    tmpChild.classList.remove("underline-forced")
                }
                let activeNavTile = document.getElementById(new_path+"-btn");
                // activeNavTile.style.background = "var(--kodak-brightest)";
                activeNavTile.classList.add("underline-forced")
            } catch{

            }
        } else if (new_path !==currentPage){
            if(new_path=="index"){
                setCurrentPage("index-2")
            } else{
                setCurrentPage(new_path)
            }
        }
    }, [location])


    function navFeatureClicked(targRef, refreshFlag = false){
        targRef.current.style.pointerEvents = 'none';

        if(refreshFlag){
            try{
                setTimeout(() => {
                    targRef.current.style.pointerEvents= 'all';
                }, 10000)
            } catch{

            }
        }
    }

    return (
        <>
            <div className="navbar-wrapper padding-gutter-sm" id="navbar">
                <div
                    key={"upper-wrpper"}
                    className="upper-wrapper"
                    style={{
                        justifyContent: `${isMobile==2 ? "space-between" : ""}`
                    }}
                >
                    <div>
                        <AnimatePresence mode="wait">
                            {
                                isHomeScreen?
                                    (isMobile==0 || isMobile==1) ?
                                        <motion.div 
                                            key={"home-index-left-"+isHomeScreen}
                                            className="menu-option-wrapper index-wrapper"
                                            onMouseEnter={(e) => {
                                                cursorChange(true)
                                            }}
                                            onMouseLeave={(e) => {cursorChange(false)}}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                        >
                                            <Link to="/Index" onClick={() => {navFeatureClicked(indexOptionRef)}}>
                                                <motion.div
                                                    className={`menu-option underline`}
                                                    id="index-btn"
                                                    key={"index_hometrue-"}
                                                    // onClick={handleMenuClick}
                                                    initial="initial"
                                                    animate="animate"
                                                    exit="exit"
                                                    variants={menuToggleVariant}
                                                    custom={[currentPage=="index", isMobile==0]}
                                                    ref={indexOptionRef}
                                                >
                                                    {
                                                        "SELECTED_WORKS".split("").flat().map((char, idx) => {
                                                            return (
                                                                char=='_' ? 
                                                                    <motion.span
                                                                        key={"unique-menu-char" + idx}
                                                                        style={{ display: "inline-block" }}
                                                                        variants={menuToggleCharVariant}
                                                                        custom={idx}
                                                                    >
                                                                        &nbsp;
                                                                    </motion.span>
                                                                :
                                                                    <motion.span
                                                                        key={"unique-menu-char" + idx}
                                                                        style={{ display: "inline-block" }}
                                                                        variants={menuToggleCharVariant}
                                                                        custom={idx}
                                                                    >
                                                                        {char}
                                                                    </motion.span>
                                                            )
                                                        })
                                                    }
                                                </motion.div>
                                            </Link>
                                        </motion.div>
                                    : 
                                        <></>
                                :    
                                    (isMobile==0 || isMobile==1) ?
                                        <motion.div
                                            className="menu-option-wrapper"
                                            key={"MRJ-left-option-"+isHomeScreen}
                                            onMouseEnter={(e) => {
                                                cursorChange(true)
                                            }}
                                            onMouseLeave={(e) => {cursorChange(false)}}
                                            style={{paddingLeft: 0}}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                        >
                                            <motion.div
                                                className={`menu-option underline `}
                                                id="home-btn"
                                                key="closeToggle"
                                                // onClick={handleMenuClick}
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                variants={menuToggleVariant}
                                                custom={[currentPage=="homeomeo", isMobile==0]}
                                                style={{fontWeight: 600, letterSpacing: "2px"}}
                                                ref={homeOptionRef}
                                            >
                                                <Link to="/" onClick={() => navFeatureClicked(homeOptionRef)}>
                                                {
                                                    "MICHAEL_R._JOHNSON".split("").flat().map((char, idx) => {
                                                        return (
                                                            char=="_" ? 
                                                                <motion.span
                                                                    key={"unique-menu-char-" + idx +"-"+ {isHomeScreen}}
                                                                    style={{ display: "inline-block" }}
                                                                    variants={menuToggleCharVariant}
                                                                    custom={idx}
                                                                >
                                                                    &nbsp;
                                                                </motion.span>
                                                            :
                                                                <motion.span
                                                                    key={"unique-menu-char-" + idx +"-"+ {isHomeScreen}}
                                                                    style={{ display: "inline-block" }}
                                                                    variants={menuToggleCharVariant}
                                                                    custom={idx}
                                                                >
                                                                    {char}
                                                                </motion.span>
                                                        )
                                                    })
                                                }
                                                </Link>
                                            </motion.div>
                                        </motion.div>
                                    :   
                                    <motion.div
                                    id="call-sign"
                                    key={"call-sign-tester-"+isHomeScreen}
                                    className="call-sign"
                                    variants={callSignContainerVariants}
                                    initial="hidden"
                                    animate="show"
                                    exit="exit"
                                >
                                    <Link to="/">
                                    {
                                        isMobile ?
                                            callSignMob.split("").flat().map((callChar, callIdx) => {
                                                return (
                                                    callChar=="_" ?
                                                        <motion.span
                                                            key={"call-sign-" + callChar + "-" + callIdx + "-" + isHomeScreen}
                                                            variants={callSignVariants}
                                                            className="call-sign__char"
                                                            style={{
                                                                letterSpacing: `${isMobile==1 ? "10px" : "4px"}`
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </motion.span>
                                                        :
                                                        callChar=="." ?
                                                            <motion.span
                                                                key={"call-sign-" + callChar + "-" + callIdx}
                                                                variants={callSignVariants}
                                                                className="call-sign__char"
                                                                style={{
                                                                    letterSpacing: `${isMobile==1 ? "10px" : "4px"}`
                                                                }}
                                                            >
                                                                {callChar.toLocaleUpperCase()}
                                                            </motion.span>
                                                            :
                                                            <motion.span
                                                                key={"call-sign-" + callChar + "-" + callIdx}
                                                                variants={callSignVariants}
                                                                className="call-sign__char"
                                                                style={{
                                                                    letterSpacing: `${isMobile==1 ? "4px" : "2px"}`
                                                                }}
                                                            >
                                                                {callChar.toLocaleUpperCase()}
                                                            </motion.span>
                                                )
                                            })
                                            :
                                            callSign.split("").flat().map((callChar, callIdx) => {
                                                return (
                                                    callChar=="_" ?
                                                        <motion.span
                                                            key={"call-sign-" + callChar + "-" + callIdx}
                                                            variants={callSignVariants}
                                                            className="call-sign__char"
                                                        >
                                                            &nbsp;
                                                        </motion.span>
                                                        :
                                                        <motion.span
                                                            key={"call-sign-" + callChar + "-" + callIdx}
                                                            variants={callSignVariants}
                                                            className="call-sign__char"
                                                        >
                                                            {callChar.toLocaleUpperCase()}
                                                        </motion.span>
                                                )
                                            })

                                    }
                                    </Link>
                                </motion.div>
                            }
                        </AnimatePresence>
                    </div>
                    <motion.div
                        className="local-wrapper"
                        style={{
                            left: `${isMobile==2 ? "0%" : ""}`,
                            transform: `${isMobile==2 ? "translateX(0)" : ""}`
                        }}
                        onMouseEnter={(e) => {setCursorText(""); cursorChange(true);}}
                        onMouseLeave={(e) => {cursorChange(false)}}
                    >
                        <AnimatePresence mode="wait">
                            {
                            isHomeScreen &&
                                <motion.div
                                    id="call-sign"
                                    key={"call-sign-tester-"+isHomeScreen}
                                    className="call-sign"
                                    variants={callSignContainerVariants}
                                    initial="hidden"
                                    animate="show"
                                    exit="exit"
                                >
                                    <Link to="/">
                                    {
                                        isMobile ?
                                            callSignMob.split("").flat().map((callChar, callIdx) => {
                                                return (
                                                    callChar=="_" ?
                                                        <motion.span
                                                            key={"call-sign-" + callChar + "-" + callIdx + "-" + isHomeScreen}
                                                            variants={callSignVariants}
                                                            className="call-sign__char"
                                                            style={{
                                                                letterSpacing: `${isMobile==1 ? "10px" : "4px"}`
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </motion.span>
                                                        :
                                                        callChar=="." ?
                                                            <motion.span
                                                                key={"call-sign-" + callChar + "-" + callIdx}
                                                                variants={callSignVariants}
                                                                className="call-sign__char"
                                                                style={{
                                                                    letterSpacing: `${isMobile==1 ? "10px" : "4px"}`
                                                                }}
                                                            >
                                                                {callChar.toLocaleUpperCase()}
                                                            </motion.span>
                                                            :
                                                            <motion.span
                                                                key={"call-sign-" + callChar + "-" + callIdx}
                                                                variants={callSignVariants}
                                                                className="call-sign__char"
                                                                style={{
                                                                    letterSpacing: `${isMobile==1 ? "4px" : "2px"}`
                                                                }}
                                                            >
                                                                {callChar.toLocaleUpperCase()}
                                                            </motion.span>
                                                )
                                            })
                                            :
                                            callSign.split("").flat().map((callChar, callIdx) => {
                                                return (
                                                    callChar=="_" ?
                                                        <motion.span
                                                            key={"call-sign-" + callChar + "-" + callIdx}
                                                            variants={callSignVariants}
                                                            className="call-sign__char"
                                                        >
                                                            &nbsp;
                                                        </motion.span>
                                                        :
                                                        <motion.span
                                                            key={"call-sign-" + callChar + "-" + callIdx}
                                                            style={{opacity: 1}}
                                                            className="call-sign__char"
                                                        >
                                                            {callChar.toLocaleUpperCase()}
                                                        </motion.span>
                                                )
                                            })

                                    }
                                    </Link>
                                </motion.div>
                            }
                        </AnimatePresence>
                    </motion.div>
                    <div className="menu-right">
                        <AnimatePresence mode="wait">
                            {
                                isMobile==2 || !isHomeScreen ?
                                <motion.div
                                    key={"index-right-"}
                                    className="menu-option-wrapper index-wrapper"
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                >
                                    <Link to="/Index" onClick={() => navFeatureClicked(indexTwoOptionRef, true)}>
                                        <motion.div
                                            className={`menu-option underline`}
                                            id="index-2-btn"
                                            key={"index-isHome"}
                                            // onClick={handleMenuClick}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            variants={menuToggleVariant}
                                            custom={[currentPage=="index-2", isMobile==0]}
                                            ref={indexTwoOptionRef}
                                        >
                                            {
                                                "SELECTED_WORKS".split("").flat().map((char, idx) => {
                                                    return (
                                                        char=='_' ? 
                                                            <motion.span
                                                                key={"unique-menu-char" + idx}
                                                                style={{ display: "inline-block" }}
                                                                variants={menuToggleCharVariant}
                                                                custom={idx}
                                                            >
                                                                &nbsp;
                                                            </motion.span>
                                                        :
                                                            <motion.span
                                                                key={"unique-menu-char" + idx}
                                                                style={{ display: "inline-block" }}
                                                                variants={menuToggleCharVariant}
                                                                custom={idx}
                                                            >
                                                                {char}
                                                            </motion.span>
                                                    )
                                                })
                                            }
                                        </motion.div>
                                    </Link>
                                </motion.div>
                                :
                                <></>
                            }
                        </AnimatePresence>
                        {/* <Link to="/Showreel" onClick={() => navFeatureClicked(showreelOptionRef, true)}>
                            <div className="menu-option-wrapper showreel-wrapper">
                                <AnimatePresence mode="wait">
                                    <motion.div
                                        className={`menu-option underline`}
                                        id="showreel-btn"
                                        key="showreel"
                                        // onClick={handleMenuClick}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        variants={menuToggleVariant}
                                        custom={[currentPage=="showreel", isMobile==0]}
                                        onMouseEnter={(e) => {setCursorText(""); cursorChange(true);}}
                                        onMouseLeave={(e) => {cursorChange(false)}}
                                        ref={showreelOptionRef}
                                    >
                                        {
                                            "SHOWREEL".split("").flat().map((char, idx) => {
                                                return (
                                                    <motion.span
                                                        key={"unique-menu-char" + idx}
                                                        style={{ display: "inline-block" }}
                                                        variants={menuToggleCharVariant}
                                                        custom={idx}
                                                    >
                                                        {char}
                                                    </motion.span>
                                                )
                                            })
                                        }
                                    </motion.div>
                                </AnimatePresence>
                            </div>
                        </Link> */}
                        <Link to="/Information" onClick={() => navFeatureClicked(contactOptionRef, true)}>
                            <div
                                className="menu-option-wrapper"
                                id="menu-btn__wrapper"
                            >
                                <AnimatePresence mode="wait">
                                    {
                                        1 &&
                                        <motion.div
                                            className={`menu-option underline`}
                                            id="information-btn"
                                            key={"infoToggle"}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            variants={menuToggleVariant}
                                            custom={[currentPage=="information", isMobile==0]}
                                            onMouseEnter={(e) => {setCursorText(""); cursorChange(true);}}
                                            onMouseLeave={(e) => {cursorChange(false)}}
                                            ref={contactOptionRef}
                                        >
                                            {
                                                "INFO".split("").flat().map((char, idx) => {
                                                    return (
                                                        <motion.span
                                                            key={"unique-menu-char" + idx}
                                                            style={{ display: "inline-block" }}
                                                            variants={menuToggleCharVariant}
                                                            custom={idx}
                                                        >
                                                            {char}
                                                        </motion.span>
                                                    )
                                                })
                                            }
                                        </motion.div>
                                    }
                                </AnimatePresence>
                            </div>
                        </Link>

                    </div>
                </div>
                {/* <AnimatePresence mode="wait">
            {
                !menuActive &&
                <motion.div 
                    key="lower-wrapper"
                    className="lower-wrapper"
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={lowerVariants}
                >
                    <motion.div 
                        key="lower-scroll"
                        id="percent-scroll"
                        className="bottom-left"
                        variants={lowerVariants}
                    >
                        
                            26%
                    </motion.div>
                    <motion.div
                        key="lower_copyright"
                        className="bottom-right"
                        variants={lowerVariants}
                    >
                            ©2023
                    </motion.div>
                </motion.div>
            }
        </AnimatePresence> */}
            </div>
        </>
    )
}

export default Navbar;