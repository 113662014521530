export const pausedOverlayParentVariants = {
    initial:{
        transition: {
            staggerChildren: 0.05
        }
    },
    animate:{
        transition: {
            staggerChildren: 0.05
        }
    },
    exit:{
        transition: {
            staggerChildren: 0.05
        }
    }
}

export const pausedOverlayVariants = {
    initial: {
        transform: 'translate3d(0, 100%, 0)'
    },
    animate: {
        transform: 'translate3d(0, 0, 0)',
        transition:{
            duration: '0.75',
            ease: [0.16, 1, 0.3, 1]
        }
    },
    exit: {
        transform: 'translate3d(0, 100%, 0)',
        transition:{
            duration: '0.55',
            ease: [0.55, 0, 1, 0.45]
        }
    }
}

export const playIconsVariants ={
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
        transition:{duration: 0.225,
        ease: 'easeOut'}
    },
    exit: {
        opacity: 0,
        transition:{duration: 0.225,
            ease: 'easeOut'}
    }
}