import React, { useRef, useState, useEffect } from "react";
import './homepage.css';
import { motion } from "framer-motion"
import { primaryInput } from 'detect-it';
import LocomotiveScroll from 'locomotive-scroll';
import ProgressiveBackgroundImg from "../../components/ProgressiveBackgroundImg/ProgressiveBackgroundImg";
import './svg.css';
import { gridItems } from "../../globals/data";
import { Link } from "react-router-dom";
import { getDevice, sanitiser } from "../../globals/helpers";
import { updateTime, calcCenterMove } from "../../globals/helpers";
import { cursorChange } from "../../globals/helpers";

function Homepage({controlAuxCursor, setIsLoading, setIsHomeScreen}) {
  const [localTime, setLocalTime] = useState(null);
  const [selectedThumb, setSelectedThumb] = useState([null]);
  const [scroll, setScroll] = useState(null);
  const [gridCols, setGridCols] = React.useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);

  const gridThumbRefs = useRef([]);
  const giTitleRef = useRef([]);

  const tabletBreak = 1180;
  const tableBreak = 860;
  const mobileBreak = 700;
  const largeItems = [4, 11, 16, 21]

  const gridRef = React.useCallback(node => {
    if(node){
      updateContactPrint();
    }
  }, []);
  
  function updateContactPrint(){
      let homeGrid = document.getElementById("homepage-grid");
      try{
        if(getDevice()=="mobile"){
          homeGrid.style.rowGap = (68 / 2968) * homeGrid.offsetWidth + ( 2 * (22/ (2968)) * homeGrid.offsetWidth) + "px";
        }else if(getDevice()=="tablet"){
          homeGrid.style.rowGap = (40 / 2968) * homeGrid.offsetWidth + ( 2 * (22/ (2968)) * homeGrid.offsetWidth) + "px";
        } else{
          homeGrid.style.rowGap = (54 / 2968) * homeGrid.offsetWidth + ( 2 * (22/ (2968)) * homeGrid.offsetWidth) + "px";
        }
      }catch(err){}
  }

  function setContactHeights() {
    for (let i=0; i< giTitleRef.current.length; i++){
      try{
        giTitleRef.current[i].style.marginBottom = 30 + "px"
      }
      catch(err){}
    }
  }

  function handleHomeScroll(scrollArgs, insigEles, randMultipliers){
    let scroll = scrollArgs.scroll;
    let width = window.innerWidth;
    if (width > 860){
      try{
        for(let i=0; i<insigEles.length; i++){
          let rand = randMultipliers[i];
          insigEles[i].style.transform = "translate3d(0,"+(-scroll.y/(3.25+rand)).toPrecision(3) +"px,0)"
        }
      } catch(err){
        console.log("error caught within handling of scroll")
      }
    }
  }

  function handleResize(e=false){
    let width;
    let height;
    if (e){
      width = e.target.innerWidth;
      height = e.target.innerHeight;
    } else {
      width = window.innerWidth;
      height = window.innerHeight;
    }

    if(width > tabletBreak){
      setGridCols(5)
    } else if(width > tableBreak && width <= tabletBreak ){
      if(width > height){
        setGridCols(4)
      }
      else{
        setGridCols(3)
      }
    } else if (width > mobileBreak && width <= tableBreak){
      setGridCols(2)
    } else if (width <= mobileBreak){
      setGridCols(2)
    }
  }

  useEffect(() => {
    let nVer;
    let nAgt;
    let browserName;
    let fullVersion;
    let majorVersion;
    let verOffset;
    let nameOffset;
    nVer = navigator.appVersion;
    nAgt = navigator.userAgent;
    browserName  = navigator.appName;
    fullVersion  = ''+parseFloat(navigator.appVersion); 
    majorVersion = parseInt(navigator.appVersion,10);

    // In Opera, the true version is after "OPR" or after "Version"
    if ((verOffset=nAgt.indexOf("OPR"))!=-1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset+4);
    if ((verOffset=nAgt.indexOf("Version"))!=-1) 
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In MS Edge, the true version is after "Edg" in userAgent
    else if ((verOffset=nAgt.indexOf("Edg"))!=-1) {
    browserName = "Microsoft Edge";
    fullVersion = nAgt.substring(verOffset+4);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset+7);
    if ((verOffset=nAgt.indexOf("Version"))!=-1) 
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
              (verOffset=nAgt.lastIndexOf('/')) ) 
    {
    browserName = nAgt.substring(nameOffset,verOffset);
    fullVersion = nAgt.substring(verOffset+1);
    if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
    }

    if (browserName.toLowerCase() == 'firefox'){
      setIsFirefox(true);
    }

    document.documentElement.style.setProperty("overflow-y", "");
    setIsHomeScreen(true);
    updateTime(setLocalTime)
    let curDate = null;
    let timeInterval = setInterval(function() {
        updateTime(setLocalTime, curDate)
    }, 5000)
    window.scrollTo(0,0);
    try{
      document.getElementsByClassName("local-wrapper")[0].classList.remove("hide");
    }catch(err){
      
    }
    setContactHeights();

    let tmpScroll = null;
    let testInterval = setInterval(() => {
      let targScrollEle = document.getElementById('main-container');
      let insigEles = document.getElementsByClassName("call-sign__char");

      if(targScrollEle && insigEles){
        if (primaryInput=="mouse" || (getDevice() !=="mobile" && getDevice() !=="tablet")){
          tmpScroll = new LocomotiveScroll({
            el: targScrollEle,
            smooth: true,
            lerp: 0.05,
            touchMultiplier: 4,
            smartphone: {
                smooth: true,
                direction: 'vertical'
            },
            tablet: {
                smooth: true,
                direction: 'vertical',
    
            },
            reloadOnContextChange: true
          });
        }
        
        let insigMultipliers = [];
        for(let i=0; i<insigEles.length; i++){
          insigMultipliers.push(((Math.random())-0.5)*0.25);
        }
        if(tmpScroll){
          tmpScroll.destroy();
          let notAdjustingScroll = true;
          setTimeout(function(){
            tmpScroll.init()
            tmpScroll.on('scroll', (args) => {
              if (args.scroll.y==args.limit.y && notAdjustingScroll){
                notAdjustingScroll = false;
                setTimeout(() => {
                  notAdjustingScroll = true;
                }, 250)
                tmpScroll.scrollTo(
                  'bottom', {
                    'offset': -2,
                    'duration': 0.25,
                  }
                )
              }
              handleHomeScroll(args, insigEles, insigMultipliers)
            });
          }, 100);
          setScroll(tmpScroll);
        }
        clearInterval(testInterval)
      }
    }, 200)

    handleResize();
    window.addEventListener('resize', (e) => handleResize(e), true);
    return () => {
      try{
        tmpScroll.destroy();
        setIsHomeScreen(false);
      }
      catch(err){
        console.log("error destorying scroll")
      }
      try{
        clearInterval(timeInterval)
      } catch(err){
        console.log("error destroying time interval")
      }
    };
  }, [])

  const footerVariants = {
    initial: {
      opacity: 0
    },
    animate: {
        opacity: 1,
        transition: {
          duration: 1.25,
          ease: [0.33, 1, 0.68, 1],
          delay: 0.65
      }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.55,
            ease: [0.33, 1, 0.68, 1]
        }
    }
  }

  const giTitleVariants = {
    initial: {
      transform: "translate3d(0px, 100%, 0px)",
      opacity: 0,
      transition: {
          duration: 0.55,
          ease: [0.33, 1, 0.68, 1]
      }
    },
    animate: i => ({
        transform: "translate3d(0px, 0%, 0px)",
        opacity: 1,
        transition: {
          duration: 0.85,
          ease: [0.33, 1, 0.68, 1],
          delay: 0.25 + 0.025 * i
      }
    }),
    exit: i => ({
        transform: "translate3d(0px, 100%, 0px)",
        opacity: 0,
        transition: {
            duration: 0.55,
            ease: [0.33, 1, 0.68, 1]
        }
    })
  }

  const giThumbVariants = {
    initial: {
      opacity: 0,
      transition: {
          duration: 0.55,
          ease: [0.33, 1, 0.68, 1]
      }
    },
    animate: i => ({
        opacity: 1,
        transition: {
          duration: 0.75,
          ease: [0.33, 1, 0.68, 1],
          delay: 0.025 * i
      }
    }),
    exit: i => ({
        opacity: i instanceof Array ? 1 : 0,
        transform: i instanceof Array ? 'translate3d('+i[0]+'px, '+i[1]+'px, 0)' : "",
        transition: {
            duration: i instanceof Array  ? 0.75 : 0.35,
            ease: [0.33, 1, 0.68, 1],
            delay: i instanceof Array ? 0.45 : 0.35
        }
    })
  }

  const revGridTitleVariants = {
    rest: {
      transform: "translate3d(0px, 0%, 0px) scale(1.05,0.975)",
      // opacity: 1,
      transition: {
          duration: 0.55,
          ease: [0.33, 1, 0.68, 1]
      }
    },
    hover: {
        
        transform: "translate3d(0px, 00%, 0px) scale(1.05,0.975)",
        // opacity: 0,
        transition: {
          duration: 0.55,
          ease: [0.33, 1, 0.68, 1]
      }
    },
    animate: {
        transform: "translate3d(0px, 0%, 0px) scale(1.05,0.975)",
        // opacity: 0,
        transition: {
            duration: 0.55,
            ease: [0.33, 1, 0.68, 1]
        }
    }
  }

  const handleLinkClick = event => {
    event.currentTarget.parentNode.style.pointerEvents = "none";
    try{
      if(scroll)
        scroll.destroy();
    } catch(err){
    }
    
    setIsClicked(true);
    let targThumb = event.currentTarget.getElementsByClassName("grid_item-visuals__wrapper")[0];
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    var xAxisMove = calcCenterMove(targThumb)[0];
    var yAxisMove = calcCenterMove(targThumb)[1];
    let idxSelected = event.currentTarget.getAttribute("proj-link");
    console.log(xAxisMove);
    setSelectedThumb([idxSelected, xAxisMove, yAxisMove]);
  };

  return (
    <>
    {
      <motion.div
        key={"homepage-wrapper"}
        className={`homepage-wrapper-full ${window.innerHeight > window.innerWidth ? "is-vertical" : "is-landscape"}`}
      >
        <div className="homepage-bottom-fixed padding-gutter-sm">
            <motion.div 
                key="lower-wrapper"
                className="lower-wrapper"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={footerVariants}
            >
                <motion.div 
                    key={"lower-local-time"+localTime}
                    id="percent-scroll"
                    className="bottom-left"
                >
                LON:&nbsp;&nbsp;<span style={{display: "inline-block", fontWeight: "600", letterSpacing: "0px"}}>{localTime}</span>
                </motion.div>
                <div className="fbr-wrapper">
                  <motion.div
                    className='lower-social-wrap'
                  >
                    <div
                    >
                      <a 
                        href="https://www.instagram.com/mrjdop"
                        target="_blank"
                        onMouseEnter={(e) => {
                          cursorChange(true)
                      }}
                      onMouseLeave={(e) => {
                          cursorChange(false)
                      }}
                        >
                        IG
                      </a>
                    </div>
                    <div><a href="https://vimeo.com/user41361139" target="_blank" onMouseEnter={(e) => {
                          cursorChange(true)
                      }}
                      onMouseLeave={(e) => {
                          cursorChange(false)
                      }}>VIM</a></div>
                    <div><a href="mailto:michaelrichardjohnson@hotmail.com" target="_blank" onMouseEnter={(e) => {
                          cursorChange(true)
                      }}
                      onMouseLeave={(e) => {
                          cursorChange(false)
                      }}>EML</a></div>
                  </motion.div>
                <motion.div
                    key="lower_copyright"
                    className="bottom-right"
                >
                  ©2024
                </motion.div>
                </div>
            </motion.div>
        </div>
        <div
          className="main-container"
          id="main-container"
          data-scroll-id="hey"
          data-scroll-container
        >
          <div className="project-section-wrapper" data-scroll-section>
            <div key={`grid_key-${gridCols}`} className={`grid__wrapper padding-gutter-sm`} id="homepage-grid" ref={gridRef}>
              {
                gridCols && gridItems.map((gi, giIdx) => {
                  let useIdx = giIdx + 1
                  let testBreaker = 7;
                  return (
                      <Link to={`projects/${sanitiser(gi.title)}`}
                        className={`${largeItems.includes(giIdx) && getDevice()=="mobile" ? "large-3" : ""}`}
                        state={{ previousPath: 'home' }}
                        onClick={handleLinkClick}
                        proj-link={giIdx}
                      >
                        <motion.div 
                          className={`grid-item ${gi.className ? gi.className : "def"}`}
                          id={`gi-${giIdx}`}
                          initial="rest" whileHover="hover" animate="rest"
                        >
                          <motion.span
                            className="grid-item__title"
                            ref={el => giTitleRef.current[giIdx] = el}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            variants={giTitleVariants}
                            custom={giIdx}
                          >
                            <div className="title-center">
                              <div>
                                <span className="gi-num kodak-text-tweaks">
                                  {useIdx < 10 ? '0' + useIdx : useIdx}
                                </span>
                      
                                <span className="gi-main-title__wrapper">
                                <motion.span
                                  className="grid-title title-glow kodak-text-tweaks"
                                >
                                  {
                                      gi.title.split("_").map((giStr, s_idx) => {
                                        return(
                                          s_idx==0 ?
                                            <span style={{
                                              fontWeight: "bold",
                                              marginRight: "4%"
                                            }}>{giStr}</span>
                                          :
                                          <span style={{
                                            marginRight: "0.275vw"
                                          }}>{giStr}</span>
                                        )
                                      })
                                    }
                                </motion.span>
                                <motion.span
                                  variants={revGridTitleVariants}
                                  className="grid-title kodak-text-tweaks"
                                >
                                  {
                                    gi.title.split("_").map((giStr, s_idx) => {
                                      return(
                                        s_idx==0 ?
                                          <span style={{
                                            fontWeight: "bold",
                                            marginRight: "4%"
                                          }}>{giStr}</span>
                                        :
                                        <span style={{
                                          marginRight: "0.275vw"
                                        }}>{giStr}</span>
                                      )
                                    })
                                  }
                                </motion.span>
                                </span>
                              </div>
                            </div>
                          </motion.span>
                          <motion.div 
                            key={"thumb-item-"+giIdx}
                            className="grid_item-visuals__wrapper"
                            ref={el => gridThumbRefs.current[giIdx] = el}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            variants={giThumbVariants}
                            custom={giIdx==selectedThumb[0] ? [selectedThumb[1], selectedThumb[2]] : giIdx+20}
                          >
                            <ProgressiveBackgroundImg
                              key={"progressive-"+giIdx}
                              placeholderSrc={gi.thumbnails.placeholder}
                              src={gi.thumbnails.tile}
                              idx={giIdx}
                              isFirefox={isFirefox}
                            />
                            {
                              primaryInput=="mouse" && !isClicked &&
                              <motion.div
                                className="grid-item__hover"
                                style={{
                                  backgroundImage: 'url('.concat(gi.gifStyling.backgroundImage, ')')
                                }}
                                whileHover={{
                                  opacity: 1,
                                  transition: { duration: 0.45}
                                }}
                                onHoverStart={(e) => {
                                  e.target.style.backgroundImage = 'url('.concat(gi.gifStyling.backgroundImage, ')');
                                  try{
                                    let test = document.getElementById('cursor-circle');
                                    test.classList.add("cursor-active")
                                  }
                                  catch{

                                  }
                                }}
                                onHoverEnd={(e) => {
                                  try{
                                    let test = document.getElementById('cursor-circle');
                                    test.classList.remove("cursor-active")
                                  }
                                  catch{

                                  }
                                }}
                              />
                            }
                          </motion.div>
                        </motion.div>
                      </Link>
                    )
                })
              }
            </div>
          </div>
          {/* <div className="homepage-footer__wrapper" data-scroll-section>
              <motion.div
                key="homepage-footer-key"
                className="homepage-footer"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={footerVariants}
                style={{
                  fontWeight: "bold",
                  fontFamily: "IBM Plex Mono"
                  
                }}
              >
                <div className="ftr-upper-tier">
                  <span
                    className="underline-kodak"
                    onMouseEnter={() => {cursorChange(true)}}
                    onMouseLeave={() => {cursorChange(false)}}
                  >
                    <a href="https://www.instagram.com/mrjdop" target="_blank" rel="noreferrer" >
                      INSTAGRAM.&nbsp;
                    </a>
                  </span>
                  <span className="underline-kodak"
                    onMouseEnter={() => {cursorChange(true)}}
                    onMouseLeave={() => {cursorChange(false)}}
                  >
                    <a href="https://vimeo.com/user41361139" target="_blank" rel="noreferrer" >
                      VIMEO.
                    </a>
                  </span>
                  <span className="underline-kodak"
                    onMouseEnter={() => {cursorChange(true)}}
                    onMouseLeave={() => {cursorChange(false)}}
                  >
                    <a href="mailto:michaelrichardjohnson@hotmail.com" target="_blank" rel="noreferrer" >
                    CONTACT.
                    </a>
                  </span>
                </div>
              </motion.div>
          </div> */}
        </div> 
      </motion.div>
    }
    </>
  )
}

export default Homepage;