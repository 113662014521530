import React, {useState, useRef, useEffect} from "react";
import { AnimatePresence, motion } from "framer-motion"
import './Index.css';
import './3d.css';
import { easeInOutCubic, easeOutCubic } from "../../globals/animationFunctions";
import { deviceType, primaryInput } from "detect-it";
import { getDevice } from "../../globals/helpers";
import IndexItem from '../../components/IndexItem/IndexItem';
import { gridItems } from "../../globals/data";
import { sanitiser } from "../../globals/helpers";
import { Link } from 'react-router-dom';
import { cursorChange } from "../../globals/helpers";
import { workTags } from "../../globals/data";

function Index({controlAuxCursor, setIsLoading, isOutdatedSafari}) {
    const [currentTitle, setCurrentTitle] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const percScrollRef = useRef(null);
    const [hasUserScrolled, setHasUserScrolled] = useState(false);
    const [viewDevice, setViewDevice] = useState(null);
    const [completeWorks, setCompleteWorks] = useState(gridItems.slice(0,9));
    const [templateWorks, setTemplateWorks] = useState(gridItems.slice(0,9));
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedOld, setSelectedOld] = useState(null);
    const pageLoadedRef = useRef(null);

    let zEles = [];
    const itemZ = deviceType == "mouseOnly" ? 1.5 : 1;
    const camSpeed = 550;
    const sp = 1.15;
    let itemCount = 0;
    let randomDurations = [];

    const indexTitleVariants = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1
        },
        exit:{
            opacity: 0
        }
    }

    const scrollSignifierWrapperVariants = {
        initial: {
        },
        animate: { 
        },
        exit:{
            transition: {
                staggerChildren: 0.015
            }
        }
    }

    const scrollSignifierVariants = {
        initial: {
        },
        animate: { 
            opacity: 1
        },
        exit:{
            opacity: 0,
            transition:{
                duration: 0.85,
                ease: [0.33, 1, 0.68, 1]
            }
        }
    }

    const indexPageVariants = {
        initial: {
            opacity: 0
        }, 
        animate: {
            opacity: 1,
            transition: {
                delay: 0.35,
                duration: 0.35
            }
        },
        exit: {
            opacity: 0
        }
    }

    const perspectiveOrigin = {
        x: parseFloat(
            getComputedStyle(document.documentElement).getPropertyValue(
                "--scenePerspectiveOriginX"
            )
        ),
        y: parseFloat(
            getComputedStyle(document.documentElement).getPropertyValue(
                "--scenePerspectiveOriginY"
            )
        ),
        maxGap: 1
    };

    function moveCamera(userInitiated, e = null) {
        if(userInitiated){
            setHasUserScrolled(true);
        }

        if (e){
            e.preventDefault();
        }
        try{
            document.documentElement.style.setProperty("--cameraZ", window.pageYOffset);
        } catch(err){}
        let camZ = window.pageYOffset;
        // These two vars only change on tagClicked event. should be tracked and updated by this interaction, not monitored in here
        // Should be a much better optimised solution with this alteration implemented
        // ddd-wrapper are elements (thumbs) currently rendered on the page (selectede items)
        let zEles;
        try{
            zEles = document.getElementsByClassName("ddd-wrapper");
        } catch{}
        let workOptions;
        try{
            workOptions = document.querySelectorAll('div.works-option:not(.work-unselected)');
        } catch{}

        let maxDistance;
        try{
            maxDistance = (zEles.length) * camSpeed * itemZ;
        } catch{}

        try{
            for (let i=0; i< zEles.length; i++){
                let target = zEles[i];
                let eleDistance = (itemZ * camSpeed * (i+1) * -1) + camZ;
                let blurMultiplier;
                let workOption = workOptions[i];
                if(percScrollRef.current){
                    if(!percScrollRef.current.classList.contains('shortening-blocker')){
                        let percScrolled;
                        if (i + 1==zEles.length){
                            percScrolled = Math.min(Math.max(((maxDistance + eleDistance) / maxDistance) * 100, 0), 100);
                            percScrollRef.current.style.width=percScrolled + "%";
                        }
                    }
                }
                if (eleDistance < -3000){
                    if (target.style.display !=="none"){
                        target.style.display = "none";
                        target.classList.remove("ddd-in-view");
                        try{
                            workOption.classList.remove("option-scrolled")
                        }
                        catch(e){
                            console.log(e)
                        }
                    }
                }
                else{
                    if(target.style.display=="none"){
                        target.classList.add("ddd-in-view");
                        target.style.display = "block";
                    }
                    if(eleDistance > 300){
                        blurMultiplier = 0;
                    }
                    else{
                        if(eleDistance > -200){
                            if (["mobile", "tablet"].includes(getDevice())){
                                setCurrentTitle(target.innerText);
                            }
                            let prevWorkOptions = document.getElementsByClassName("option-scrolled");
                            if(prevWorkOptions.length > 0){
                                for (let j=0; j< prevWorkOptions.length; j++){
                                    prevWorkOptions[j].classList.remove("option-scrolled");   
                                }
                            }
                            try{
                                workOption.classList.add("option-scrolled");
                            }
                            catch (e){
                            }
                            
                        }   
                        let tempCalc;
                        if (deviceType == "touch")
                            tempCalc = (1 + ( 1 * (eleDistance)/3000));
                        else
                            tempCalc = (1 + ( 1 * eleDistance/3000));
                        blurMultiplier = easeInOutCubic(tempCalc).toPrecision(4);
                        target.style.webkitFilter = "blur(" + (80 - (80 * blurMultiplier)) + "px)";
                        target.style.opacity = Math.min(2*blurMultiplier, 1);
                    }
                }
                target.style.transform = "translateZ("+eleDistance+ "px";     
            }
        } catch(err){
            console.log(err)
        }
    }

    function setSceneHeight(films) {
        let numberOfItems;
        try{
            if (Array.isArray(films))
                numberOfItems = films.length;
            else
                numberOfItems = films

            const height =
                window.innerHeight +
                sp * camSpeed +
                itemZ * camSpeed * (numberOfItems-0.65);
            
            document.documentElement.style.setProperty("--viewportHeight", height);
            document.documentElement.style.setProperty("overflow-y", "");
            } catch(err){console.log("error setting height");}
    }

    function handleResize(){
        try{
            setViewDevice(getDevice());
        } catch(err){
            console.log(err)
        }
    }

    function moveCameraAngle(event) {
        let xGap, yGap, newPerspectiveOriginX, newPerspectiveOriginY;
        try{
            xGap = (((event.clientX - window.innerWidth / 2) * 100) / (window.innerWidth / 2));
            yGap = (((event.clientY - window.innerHeight / 2) * 100) /(window.innerHeight / 2));
            newPerspectiveOriginX = perspectiveOrigin.x - (xGap * perspectiveOrigin.maxGap) / 200;
            newPerspectiveOriginY = perspectiveOrigin.y - (yGap * perspectiveOrigin.maxGap) / 200;
        } catch(err){
            console.log("Error encountered init'ing camera vars")
        }

        try{
            document.documentElement.style.setProperty(
                "--scenePerspectiveOriginX",
                newPerspectiveOriginX
            );
        } catch(err){
            console.log("error encountered while setting X perspective")
        }
        try{
            document.documentElement.style.setProperty(
                "--scenePerspectiveOriginY",
                newPerspectiveOriginY
            );
        } catch(err){
            console.log("error encountered while setting Y perspective")
        }
    }

    function startPercWidthAnimation(){
        let stop = false;
        let startx = Number(String(percScrollRef.current.style.width).slice(0, -1));
        let destx = 0;
        let duration = 300;
        let start = null;
        let end = null;
        
        function startAnim(timestamp){
            start = timestamp;
            end = start + duration;
            draw(timestamp)
        }

        function draw(now){
            if (stop){
                // percScrollRef.current.classList.remove('shortening-blocker');
                return
            };
            if (now - start >= duration) stop = true;
            let p = (now - start) / duration;
            let val = easeOutCubic(p);
            let x = startx + (destx - startx) * val;
            percScrollRef.current.style.width = x + '%';
            requestAnimationFrame(draw)
        }
        try{
            requestAnimationFrame(startAnim)
        } catch(err){
            console.log(err)
        }
    }

    function tagClicked(e, tag){
        try{
            itemCount = 0;        
            let isPreSelected = e.target.classList.contains("tag-select");
            let activeTags = document.getElementsByClassName("tag-select");
            setCurrentTitle("");
            try{
                if (percScrollRef){
                    percScrollRef.current.classList.add('shortening-blocker');
                    startPercWidthAnimation();
                }
            } catch{}

            for (let i = 0; i < activeTags.length; i++){
                activeTags[i].classList.remove("tag-select")
            }
            if (isPreSelected){
                setTemplateWorks(completeWorks);
                setSelectedTag(null)
                setSelectedOld(selectedTag);
                setSceneHeight(completeWorks);
            }
            else{
                try{
                    e.target.classList.add("tag-select");
                } catch(err){
                    console.log("error encountered trying to set the selected tag!")
                }
                if (selectedTag !== null){
                    setSelectedOld(selectedTag);
                }
                setSelectedTag(tag.id)
                let selectedAmt = 0
                let tmpSelected = [];
                try{
                    for (let i = 0; i < completeWorks.length; i++){
                        if(completeWorks[i].tags.includes(tag.id)){
                            selectedAmt += 1
                            tmpSelected.push(completeWorks[i])
                        }
                    }
                } catch(err){}

                setTimeout(() => {
                    setTemplateWorks(tmpSelected);
                    setSceneHeight(selectedAmt);  
                    setTimeout(() => {
                        moveCamera(false);
                    }, 400)
                }, 600)
            }

            setTimeout(() => {
                window.scrollTo(0,0)
                document.documentElement.style.setProperty("--cameraZ", 0);
                try{
                    if(percScrollRef) percScrollRef.current.classList.remove('shortening-blocker');
                } catch{}
            }, 100)
        } catch(err){
            console.log(err)
        }
    }


    useEffect(() => {
        setSceneHeight(templateWorks.length)
        if (primaryInput=="mouse" || (getDevice() !=="mobile" && getDevice() !=="tablet")){
            document.documentElement.style.overflowY = "visible";
        }
        
        controlAuxCursor(true);
        handleResize();
        let resizer = window.addEventListener('resize', (e) => handleResize(e), true);
        templateWorks.map((item, idx) => {
            let randDur = (Math.random() * (0.85 - 0.4) + 0.4) + "s"
            randomDurations.push(randDur)
        })
        
        let scrollListener = window.addEventListener("scroll", (e) => moveCamera(true, e), false);
        let mouseMove;
        // mouseMove = window.addEventListener("mousemove", moveCameraAngle);
        document.documentElement.style.setProperty("overflow-y", "");
        
        setTimeout(() => {
            moveCamera(false)
        }, 400)
        return function cleanUp(){
            if (primaryInput=="mouse" || (getDevice() !=="mobile" && getDevice() !=="tablet")){
                document.documentElement.style.overflowY = "hidden";
            }
            try{
                window.removeEventListener("scroll", moveCamera);
                window.removeEventListener(scrollListener);
            } catch(err){}
            if(mouseMove)
                window.removeEventListener("mousemove", moveCameraAngle)
            window.removeEventListener("resize", handleResize);
        }
        
    }, [])

    return (
        <>
            {
                isLoaded &&
                <motion.div
                    id="index-page-wrapper-id"
                    ref={pageLoadedRef}
                    key={'index-wrapper-'+isLoaded}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={indexPageVariants}
                >
                    <div className="viewport">
                        <AnimatePresence>
                            {
                                !hasUserScrolled &&
                                <>
                                    <motion.div
                                        className="user-input-signifier"
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                    >
                                        <motion.div
                                            className="scroll-signifier"
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            variants={scrollSignifierWrapperVariants}
                                        >
                                            {
                                                "SCROLL_TO_NAVIGATE".split("").map((charItem, cIdx) => {
                                                    return(
                                                        charItem=="_" ? 
                                                            <motion.span
                                                                variants={scrollSignifierVariants}
                                                            >
                                                                &nbsp;
                                                            </motion.span>
                                                        :
                                                            <motion.span
                                                                variants={scrollSignifierVariants}
                                                            >
                                                                {charItem}
                                                            </motion.span>
                                                    )
                                                })
                                            }
                                            
                                        </motion.div>
                                    </motion.div>
                                </>
                            }
                        </AnimatePresence>
                        { ["mobile", "tablet"].includes(viewDevice) &&
                            <div className="proj-title__wrapper">
                                <AnimatePresence>
                                    {sanitiser(currentTitle) !== "" ?
                                        <Link to={"/projects/"+ sanitiser(currentTitle)}>
                                            <motion.span
                                                key={currentTitle+"--title"}
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                variants={indexTitleVariants}
                                                className="index-cur-title"
                                                style={{
                                                    top: isOutdatedSafari ? "50%" : "50dvh"
                                                }}
                                            >
                                                {currentTitle}
                                            </motion.span>
                                        </Link>
                                        :
                                        <></>
                                    }
                                </AnimatePresence>
                            </div>
                        }
                        <div className="scene3D-container">
                            <AnimatePresence mode = "wait">
                                <motion.div 
                                    className="scene3D"
                                    key={`select-results-${selectedTag}`}
                                    initial={{
                                        transform: 'translate3d(0,0,0)',
                                
                                    }}
                                    animate={{
                                        transform: 'translate3d(0,0,0)'
                                    }}
                                    exit={{transform: 'translate3d(0,0,2000px)'}}
                                    transition={{
                                        easings: 'easeOut',
                                        duration: 0.5
                                    }}
                                >
                                    {templateWorks.map((item, idx) => {  
                                        itemCount+=1
                                        return(
                                            (selectedTag==null || item.tags.includes(selectedTag)) && 
                                            <>
                                                <IndexItem 
                                                    key={item.title + "-" + selectedTag}
                                                    title={item.title}
                                                    placeholderSrc={item.thumbnails.placeholder}
                                                    backgroundSrc={item.thumbnails.mobile}
                                                    isMobile={["mobile", "tablet"].includes(viewDevice)}
                                                    index={itemCount-1}
                                                />  
                                                
                                            </>
                                        )
                                        
                                    })}
                                </motion.div>
                            </AnimatePresence>
                        </div>
                    </div>
                    <div className="index-page__wrapper">
                        <div className="background__wrapper">
                            
                        </div>
                        <div 
                            className="index__wrapper"
                        >
                            <div className="index-tags__wrapper">
                                <ul className="tag-list">
                                    {
                                        workTags.map((tag, tagIdx)=>{
                                            return(
                                                tagIdx==0 ?
                                                    <li>
                                                        <div className='tag-option'>
                                                            <button
                                                                className="tag-button"
                                                                onClick={(e) => {tagClicked(e, tag)}}
                                                                onMouseEnter={(e) => {
                                                                    cursorChange(true)
                                                                }}
                                                                onMouseLeave={(e) => {cursorChange(false)}}
                                                            >
                                                                <span>{tag.tagName}</span>
                                                            </button>
                                                        </div>
                                                    </li> 
                                                : 
                                                <li>
                                                    <div className='tag-option'>
                                                        <button
                                                            className="tag-button"
                                                            onClick={(e) => {tagClicked(e, tag)}}
                                                            onMouseEnter={(e) => {
                                                                cursorChange(true)
                                                            }}
                                                            onMouseLeave={(e) => {cursorChange(false)}}
                                                        >
                                                            <span>{tag.tagName}</span>
                                                        </button>
                                                    </div>
                                                </li> 
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            {!["mobile", "tablet"].includes(viewDevice) &&
                            <>
                            <div className="index-content__wrapper">
                                <ul className="nav-list">
                                    {
                                        completeWorks.map((workDict, workIdx) => {
                                            return (!(selectedTag==null || workDict.tags.includes(selectedTag))) ?
                                                <li className="works-li">
                                                    <div 
                                                        className={`works-option ${selectedTag==null || workDict.tags.includes(selectedTag) ? '' : 'work-unselected'} `}
                                                        id={`works-option-${workIdx}`}
                                                        style={{opacity: 1}}
                                                        onMouseEnter={(e) => {
                                                            cursorChange(true)
                                                        }}
                                                        onMouseLeave={(e) => {cursorChange(false)}}
                                                    >
                                                        <span className="option-decoration">-</span>
                                                            <span className="option-text">
                                                                {workDict.title}
                                                                <del>
                                                                    <span 
                                                                        className={`redacted redact-in`}
                                                                        style={{animationDuration: `${(Math.random() * (0.65 - 0.3) + 0.3)}s`}}
                                                                    />
                                                                </del>    
                                                            </span>
                                                    </div>
                                                </li>
                                                :
                                                    selectedOld !==null || workDict.tags.includes(selectedOld) ? 
                                                    <li className="works-li">
                                                        <div 
                                                            className={`works-option `}
                                                            style={{opacity: 1}}
                                                            id={`works-option-${workIdx}`}
                                                            onMouseEnter={(e) => {
                                                                cursorChange(true)
                                                            }}
                                                            onMouseLeave={(e) => {cursorChange(false)}}
                                                        >
                                                            <span className="option-decoration">-</span>
                                                            <Link to={"/projects/"+sanitiser(workDict.title)}>
                                                            <span className="option-text">
                                                                {workDict.title}
                                                                <del>
                                                                    <span 
                                                                        className={`redacted redact-out`}
                                                                        style={{animationDuration: `${(Math.random() * (0.5 - 0.3) + 0.3)}s`}}
                                                                    />
                                                                </del>    
                                                            </span>
                                                            </Link>
                                                            {/* <del>
                                                            <span className="redacted">{workDict.name}</span>
                                                            </del> */}
                                                        </div>
                                                    </li> 
                                                    :
                                                        <li className="works-li" >
                                                            <div 
                                                                id={`works-option-${workIdx}`}
                                                                className={`works-option ${selectedTag==null || workDict.tags.includes(selectedTag) ? '' : 'work-unselected'} `}
                                                                onMouseEnter={(e) => {
                                                                    cursorChange(true)
                                                                }}
                                                                onMouseLeave={(e) => {cursorChange(false)}}
                                                            >
                                                                <span className="option-decoration">-</span>
                                                                <Link to={"/projects/"+sanitiser(workDict.title)}>
                                                                <span className="option-text">{workDict.title}</span>
                                                                </Link>
                                                            </div>
                                                        </li>  
                                        })
                                    }
                                </ul>
                                <div 
                                    className="index-percentage__wrapper"
                                >
                                    <div ref={percScrollRef} id="index-scroll-perc"></div>
                                    <div 
                                        className="line-tickers"
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            height: "3px",
                                            position: "absolute",
                                            top: 0
                                        }}
                                    >
                                        {
                                            gridItems.slice(0,Math.min(templateWorks.length, 9)).map((item, idx) => {  
                                                return(
                                                    <div />
                                                )

                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                        </div>
                    </div>
                </motion.div>
            }
        </>
    )
}
  
  export default Index;