import React, {useEffect, useRef, useState, Suspense} from "react";
import Scramble from "react-scramble";
import './FirstLoader.css';
import { motion } from "framer-motion";
import { getDevice } from "../../globals/helpers";

import loaderImg1 from '../../media/preloader/desktop/fb1.webp';
import loaderImg2 from '../../media/preloader/desktop/fb2.webp';
import loaderImg3 from '../../media/preloader/desktop/fb3.webp';
import loaderImg4 from '../../media/preloader/desktop/fb4.webp';
import loaderImg5 from '../../media/preloader/desktop/fb5.webp';
import loaderImg6 from '../../media/preloader/desktop/fb6.webp';
import loaderImg7 from '../../media/preloader/desktop/fb7.webp';
import loaderImg8 from '../../media/preloader/desktop/fb8.webp';
import loaderImg9 from '../../media/preloader/desktop/fb9.webp';
import { easeOutExpo } from "../../globals/animationFunctions";
const loaderImages = [loaderImg1, loaderImg2, loaderImg3, loaderImg4, loaderImg5, loaderImg6, loaderImg7, loaderImg8, loaderImg9];

const FirstLoader = ({IsFirstLoad}) => {
    const thumbRef = useRef(null);
    const imageTileRef = useRef(null);
    const evRef = useRef(null);
    const isoRef = useRef(null);
    const loadPercRef = useRef(null);
    const animationDuration = 1.15;
    const transitionBezier = [0.64, 0, 0.78, 0];
    const fadeBezier = [0.33, 1, 0.68, 1];
    const rollBezier = [0.22, 1, 0.36, 1];
    const [viewDevice, setViewDevice] = useState(null);

    function handleResize(){
        try{
            setViewDevice(getDevice());
            let scrollEle = document.getElementById("content-scroll");
            let videoTarg = document.getElementsByClassName("shoot-video")[0];
            if (videoTarg.style.opacity==1){
                scrollEle.classList.add('no-interaction');
            }
        } catch(err){
            
        }
    }

    useEffect(() => {
        let loadInterval = 25;
        let loadProg = 0;
        handleResize();
        window.addEventListener('resize', (e) => handleResize(e), true);
        let a;
        let loadTimeout = setTimeout(() => {
            a = setInterval(() => {
                try{
                    if(loadProg >= 100){
                        clearInterval(x);
                        clearInterval(a);
                    } else{
                        loadProg += loadInterval/25;
                        if(loadPercRef.current){
                            loadPercRef.current.innerText = loadProg.toFixed(0) + "%";
                        }
                    }
                } catch{
                }
            }, loadInterval)
        }, 1500)
        
        let sourceIdx = 0;
        let x = setInterval(() => {
            if(thumbRef.current){
                thumbRef.current.srcset = loaderImages[sourceIdx]
            }
            sourceIdx = (sourceIdx + 1)%8;
        }, 185)

        let intervalLength = 10
        let y = setTimeout(() => {
            let progress = 0;
            let z = setInterval(() => {
                try{
                    progress += intervalLength/1500;
                    if(progress >= 1){
                        clearInterval(z)
                    }
                    let animProgress = easeOutExpo(progress);
                    let newEV = 1 - (animProgress * 2) ;
                    let newIso = 800 - (600 * animProgress)
                    isoRef.current.innerText = newIso.toFixed(0);
                    evRef.current.innerText = newEV > 0 ? "+" + newEV.toFixed(2): newEV.toFixed(2);
                } catch{
                    
                }
            }, intervalLength)
        }, 1000)

        return (() => {
            clearInterval(x);
            clearInterval(y);
            clearInterval(a);
        })
    }, [])

    return(
        <>
            <motion.div 
                key="main-loader__wrapper"
                className="main__preloader__wrapper padding-gutter-all"
                initial={{
                    opacity: 1,
                }}
                animate={{
                    opacity: 1,
                    transition:{
                        duration: 0.5,
                        ease: transitionBezier,
                        delay: 0.1
                    }
                }}
            >
                {
                    viewDevice !=="mobile" && viewDevice &&
                    <motion.div className="focus_ring__wrapper"
                        initial={{
                            opacity: 1
                        }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{
                            opacity: 0,
                            transition: {
                                duration: animationDuration,
                                ease: fadeBezier
                            }
                        }}
                    >
                        <div>
                            <div className="focus__unit">
                                <div className="ov-hidden">
                                    <span
                                        className="inl-block"
                                        initial={{
                                            transform: "translate(0%, 0%)"
                                        }}
                                        animate={{
                                            transform: "translate(0%, 0%)"
                                        }}
                                        exit={{
                                            transform: "translate(0%, -100%)",
                                            transition: {
                                                duration: animationDuration,
                                                ease: transitionBezier
                                            }
                                        }}
                                    >ft</span>
                                </div>
                                <div className="ov-hidden">
                                    <span
                                        className="inl-block"
                                    >m</span>
                                </div>
                            </div>
                        </div>
                        
                            <div className="focus_content__wrapper" 
                                style={{
                                    width: "150px",
                                    overflow: "hidden"                                    
                                }}
                    
                            >
                                <div className="focus_ring">
                                    <div className="dist__measure" style={{paddingRight: "15px"}}>
                                            <span>&nbsp;</span><span>&#8734;</span>
                                    </div>
                                        <div className="dist__measure">
                                            <span>20</span>
                                            <span className="focusM foc_ring_half_pos">&nbsp;5</span>
                                        </div>
                                        <div className="dist__measure" style={{paddingRight: "10px"}}>
                                            <span>10</span>
                                            <span className="focusM foc_ring_half_pos">3</span>
                                        </div>
                                        <div className="dist__measure" style={{paddingRight: "12px"}}>
                                            <span>7</span>
                                            <span className="focusM foc_ring_half_pos">2</span>
                                        </div>
                                        <div className="dist__measure" style={{paddingRight: "15px"}}>
                                            <span>5</span>
                                            <span className="focusM foc_ring_quarter_neg" >1.5</span>
                                        </div>
                                        <div className="dist__measure" style={{paddingRight: "20px"}}>
                                            <span>4</span>
                                            <span>1.2</span>
                                        </div>
                                        <div className="dist__measure" style={{paddingRight: "10px"}}>
                                            <span>&nbsp;</span>
                                            <span>1</span>
                                        </div>
                                        <div className="dist__measure" style={{paddingRight: "15px"}}>
                                            <span>3</span>
                                            <span></span>
                                        </div>
                                        <div className="dist__measure">
                                            <span>2.5</span>
                                            <span>0.8</span>
                                        </div>
                                </div>
                                <div className="focus__center"
                                    style={{
                                        marginTop: "5px",
                                        marginLeft: "0.4rem",
                                        width: "4px",
                                        height: "4px",
                                        borderRadius: "3px",
                                        bottom: "0",
                                        background: "white"
                                    }}
                                />
                        </div>
                    </motion.div>
                }
                <div className="info__wrapper ov-hidden">
                    <div className="preload__upper__wrapper">
                        <motion.div 
                            className="preload__upper"
                            initial={{
                                transform: "translate(0%, 0%)"
                            }}
                            animate={{
                                transform: "translate(0%, 0%)"
                            }}
                            exit={{
                                transform: "translate(0%, -100%)",
                                transition: {
                                    duration: animationDuration,
                                    ease: transitionBezier
                                }
                            }}
                        >
                            <div className="up_left">
                                {viewDevice !=="mobile" && viewDevice &&
                                    <>
                                        <div className="cam_opt entry_1">
                                            <div>F/S&nbsp;&nbsp;</div>
                                            <div 
                                                className="secondary-text-col"
                                                style={{
                                                    width: '6ch'
                                                }}
                                            >
                                                <Scramble 
                                                        autoStart
                                                        text="23.98"
                                                        speed="medium"
                                                        steps={[
                                                            {
                                                                roll: 50,
                                                                action: '+',
                                                                type: 'all'
                                                            },
                                                            {
                                                                roll: 10,
                                                                action: '-',
                                                                type: 'forward',
                                                            }
                                                        ]}
                                                />
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="cam_opt entry_2">
                                            <div>SHUTTER&nbsp;&nbsp;</div>
                                            <div className="secondary-text-col">
                                                <Scramble 
                                                        autoStart
                                                        text="120"
                                                        speed="medium"
                                                        steps={[
                                                            {
                                                                roll: 50,
                                                                action: '+',
                                                                type: 'all'
                                                            },
                                                            {
                                                                roll: 10,
                                                                action: '-',
                                                                type: 'forward',
                                                            }
                                                        ]}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    viewDevice=="mobile" && viewDevice &&
                                    <>
                                        <div className="secondary-text-col"
                                        style={{overflow:"hidden"}}
                                    >
                                        <motion.span
                                            style={{display: "inline-block"}}
                                            initial={{
                                                transform: "translate(0%, 0%)"
                                            }}
                                            animate={{
                                                transform: "translate(0%, 0%)"
                                            }}
                                            exit={{
                                                transform: "translate(0%, -100%)",
                                                transition: {
                                                    duration: animationDuration,
                                                    ease: transitionBezier
                                                }
                                            }}
                                        >
                                        MICHAEL R. JOHNSON
                                        </motion.span>
                                    </div>
                                    <div style={{overflow:"hidden"}}>
                                        <motion.span
                                        style={{display: "inline-block"}}
                                    initial={{
                                        transform: "translate(0%, 0%)"
                                    }}
                                    animate={{
                                        transform: "translate(0%, 0%)"
                                    }}
                                    exit={{
                                        transform: "translate(0%, -100%)",
                                        transition: {
                                            duration: animationDuration,
                                            ease: transitionBezier
                                        }
                                    }}>
                                        DIRECTOR OF PHOTOGRAPHY</motion.span></div>
                                    </>
                                }
                            </div>
                        
                            <div className="up_right">
                                <div>ISO <span className="secondary-text-col" ref={isoRef}>800</span> &nbsp;&nbsp;&nbsp;</div>
                                <div>EV <span className="secondary-text-col" ref={evRef}>+1.00</span></div>
                            </div>
                        </motion.div>
                    </div>
                    <div className="preload__lower__wrapper ">
                        <motion.div
                            id="preload__center"
                            className="ov-hidden"
                            
                        >
                            <motion.picture 
                                
                                initial={{
                                    transform: "translate(0%, 0%)"
                                }}
                                animate={{
                                    transform: "translate(0%, 0%)"
                                }}
                                exit={{
                                    transform: "translate(0%, -100%)",
                                    transition: {
                                        duration: animationDuration,
                                        ease: transitionBezier
                                    }
                                }}
                            >
                                <source ref={thumbRef}  srcSet={loaderImg1} type="image/webp" />
                                <img ref={imageTileRef} className="elUOSz" type="image/webp" />
                                {
                                    loaderImages.map((item, idx) => {
                                        return(
                                            <>
                                            <Suspense>
                                            <picture className="_1hLDkd" 
                                                style={{
                                                    position:"absolute",
                                                    top:0,
                                                    left:0,
                                                    width: "100%",
                                                    height: "100%",
                                                    zIndex:-1,
                                                    opacity:0
                                                }}
                                            >
                                                <source srcSet={item} type="image/webp" />
                                                {/* <source srcSet={item} type="image/jpg" media="(min-width: 820px)"/> */}
                                                <img className="elUOSz" src={item} alt="preload image 1"/>
                                            </picture>
                                            </Suspense>
                                            </>
                                        )
                                    })
                                }
                                
                            </motion.picture>
                            {/* <div ref={thumbRef} className="pl_thumb__wrap">

                            </div> */}
                        </motion.div>
                    </div>
                    <div className="preload__lower__wrapper ov-hidden">
                        <div 
                            className="preload__lower"
                        >
                            <div className="low_left"
                                
                            >
                                <div style={{overflow:"hidden"}}>
                                    <motion.span
                                        style={{display: "inline-block"}}
                                        initial={{
                                            transform: "translate(0%, 0%)"
                                        }}
                                        animate={{
                                            transform: "translate(0%, 0%)"
                                        }}
                                        exit={{
                                            transform: "translate(0%, -100%)",
                                            transition: {
                                                duration: animationDuration,
                                                ease: transitionBezier
                                            }
                                        }}
                                    >
                                        Loading -&nbsp;<span ref={loadPercRef}>0%</span>
                                    </motion.span>
                                </div>
                            </div>
                            {
                                viewDevice !=="mobile" && viewDevice &&
                                <div className="low_center">
                                    <div className="secondary-text-col"
                                        style={{overflow:"hidden"}}
                                    >
                                        <motion.span
                                            style={{display: "inline-block"}}
                                            initial={{
                                                transform: "translate(0%, 0%)"
                                            }}
                                            animate={{
                                                transform: "translate(0%, 0%)"
                                            }}
                                            exit={{
                                                transform: "translate(0%, -100%)",
                                                transition: {
                                                    duration: animationDuration,
                                                    ease: transitionBezier
                                                }
                                            }}
                                        >
                                        MICHAEL R. JOHNSON
                                        </motion.span>
                                    </div>
                                    <div style={{overflow:"hidden"}}>
                                        <motion.span
                                        style={{display: "inline-block"}}
                                    initial={{
                                        transform: "translate(0%, 0%)"
                                    }}
                                    animate={{
                                        transform: "translate(0%, 0%)"
                                    }}
                                    exit={{
                                        transform: "translate(0%, -100%)",
                                        transition: {
                                            duration: animationDuration,
                                            ease: transitionBezier
                                        }
                                    }}>
                                        DIRECTOR OF PHOTOGRAPHY</motion.span></div>
                                </div>
                            }

                            <div className="low_right">
                                <div className="upper-shutter">
                                    <span>&nbsp;</span>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    visibility: "hidden"
                                }}>
                                    <span>Los Angeles, LA</span>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    
                                </div>
                                <div className="lower-shutter"><span>&nbsp;</span></div>
                                <motion.div 
                                    className="local-roll__wrapper"
                                    initial={{transform: "translate(0, 0%)"}}
                                    animate={{
                                        transform: "translate(0, -100%)",
                                        transition: {
                                            delay: 1,
                                            duration: 3,
                                            ease: rollBezier
                                        }
                                    }}
                                >
                                    <div className="location__wrapper">MADRID, SPAIN</div>
                                    <div className="location__wrapper">LONDON, UK</div>
                                    <div className="location__wrapper">LAGOS, NIG</div>
                                    <div className="location__wrapper">MADRID, SPAIN</div>
                                    <div className="location__wrapper">LONDON, UK</div>
                                    <div className="location__wrapper">LAGOS, NIG</div>
                                    <div className="location__wrapper">MADRID, SPAIN</div>
                                    <motion.div 
                                        className="location__wrapper"
                                        style={{display: "inline-block"}}
                                        initial={{
                                            transform: "translate(0%, 0%)"
                                        }}
                                        animate={{
                                            transform: "translate(0%, 0%)"
                                        }}
                                        exit={{
                                            transform: "translate(0%, -100%)",
                                            transition: {
                                                duration: animationDuration,
                                                ease: transitionBezier
                                            }
                                        }}
                                        >LONDON, UK</motion.div>
                                    <div className="location__wrapper">LAGOS, NIG</div>
                                    
                                </motion.div>
                                <div className="location-scroll__fade-wrap"
                                >
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                </div>
                            
                            </div>
                        </div> 
                    </div>
                </div>
            </motion.div>
        </>
    )
}

export default FirstLoader