import React, { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { AnimatePresence, easeOut, motion, transform } from "framer-motion";
import './fallback.css'
function Fallback ({setIsLoading}){
    const headerText = "Whoaa"
    const headerText2 = "there!"
    const timerRef = useRef(null);
    const redirectRef = useRef(null);
    const [shouldRedirect, setShouldRedirect] = useState(false)
    useEffect(() => {
        setIsLoading(false)
    }, [])

    const pt1Variant = {
        animate:{
            transition:{
                staggerChildren: 0.03,
                staggerDirection: -1
            }
        }
    }

    const pt2Variant = {
        animate:{
            transition: {
                staggerChildren: 0.03,
                staggerDirection: 1
            }
        }
    }

    const letterVariants = {
        initial: {
            transform: "translate3d(0,130%,0)"
        },
        animate: i => ({
            transform: "translate3d(0,0,0)",
            transition:{
                duration: 1 + 0.1 * i,
                ease: [0.22, 1, 0.36, 1]
            }
        }),
        exit: {
            transform: "translate3d(0,0,0)"
        }
    }

    useEffect(() => {
        let count = 4;
        var x = setInterval(function() {
            if (count > 0){
                timerRef.current.innerText = count 
            }
            count -= 1
            if (count==-1){
                
                clearInterval(x);
                setShouldRedirect(true)
            }
        }, 1000)


        // let appendString = ""
        // var y = setInterval(function(){
        //     appendString += "."
        //     if(appendString.length > 3){
        //         appendString =  ""
        //     }
        //     redirectRef.current.innerText = "REDIRECTING"+appendString
        // }, 350)

        return () => { clearInterval(x)};
    }, [])

    return (
        <>
        {
            shouldRedirect ?

                <Navigate to="" />
            :
                <motion.div
                    className="404__wrapper"
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    style={{
                        color: "white",
                        fontSize: "64px",
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <div 
                        className="404-header__wrapper" 
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            overflowY:"hidden"   
                        }}
                        
                    >
                        <motion.div
                            className="pt1__wrapper"
                            variants={pt1Variant}
                        >
                            {headerText.split("").flat().map((iChar, iIdx) => {
                                return(
                                    <motion.span style={{display: "inline-block"}}variants={letterVariants} custom={headerText.length-iIdx}>{iChar.toUpperCase()}</motion.span>
                                )
                            })}
                        </motion.div>
                        <span>&nbsp;</span>
                        <motion.div
                            className="pt2__wrapper"
                            variants={pt2Variant}
                        >
                            {headerText2.split("").flat().map((iChar, iIdx) => {
                                return(
                                    <motion.span style={{display: "inline-block"}} variants={letterVariants} custom={iIdx}>{iChar.toUpperCase()}</motion.span>
                                )
                            })}
                        </motion.div>
                    </div>
                    <div className="timer__wrapper">
                        <svg className="timer" viewBox="0 0 692 692" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="346" cy="346" r="345.75" stroke="#EFEBE7" stroke-width="0.5"></circle>
                            <circle cx="346.5" cy="345.5" r="156" strokeWidth="312" strokeOffset="-250" className="__1SGKOh">
                                <animate id="op" attributeName="stroke-dashoffset" fill="freeze" values="1000;0" from="1" to="0" dur="1s" begin="0s;op.end+0s" repeatCount="1" calcMode="spline"
                                    keySplines="0.33, 1, 0.68, 1"></animate>

                            </circle>
                            {/* <circle cx="346.5" cy="345.5" r="156" strokeWidth="312" stroke="red !important" className="__1SGKOh">
                                <animate id="op1" attributeName="stroke-dashoffset" fill="freeze" values="1000;0" from="1" to="0" dur="1s" begin="1s;op1.end+1s" repeatCount="1"
                                    calcMode="spline"
                                    keySplines="0.33, 1, 0.68, 1"
                                ></animate>
                            </circle> */}

                            <circle cx="346.5" cy="345.5" r="311.25" fill="none" stroke="#EFEBE7" stroke-width="0.5" stroke-linecap="round" strokeDasharray="9 9"></circle>
                            <line x1="346.25" y1="34" x2="346.25" y2="657" stroke="#EFEBE7" stroke-width="0.5"></line>
                            <line x1="658" y1="346.25" x2="35" y2="346.25" stroke="#EFEBE7" stroke-width="0.5"></line>
                        </svg>
                        <div className="timer__content"><span ref={timerRef}>5</span></div>
                    </div>
                    <div className="a404__footer padding-gutter-sm">
                        <div className="a404__wrapper">
                            404 Error Encountered
                        </div>
                        <div 
                            className="redirect__wrapper"
                        >
                            <span>Redirecting Home</span><span className="test" style={{width: "20px", display: "inline-block"}}></span>
                        </div>
                        <div>PAGE WAS NOT FOUND</div>
                    </div>
                </motion.div>
        }
        </>
    )
}

export default Fallback