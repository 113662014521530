import React, { useContext, useState } from "react";
import "./styles.scss";
import { AnimatePresence, motion, transform } from "framer-motion";
import { easeOutElastic } from "../../globals/animationFunctions";
import { deviceType, primaryInput } from 'detect-it';


// import CustomCursorContext from "./context/CustomCursorContext";

// TODO: Hide if cursor not moved
const CustomCursor = ({cursorText, menuActive}) => {
  // const { type } = useContext(CustomCursorContext);
  let jsFlag = false;
  const [hasMoved, setHasMoved] = React.useState(false);
  const [isCircle, setIsCircle] = React.useState(false);
  const secondaryCursor = React.useRef(null);

  const cursorTextVariants ={
    initial:{
        transition: {
            staggerChildren: 0.04
        }
    },
    animate:{
        transition: {
            staggerChildren: 0.025
        }
    },
    exit:{
        transition: {
            staggerChildren: 0.025
        }
    },
}

  const cursorCircleVariants ={
    initial: {
      opacity: 0,
      // transform: "translate3d(0, 100%, 0)",
  },
  animate: {
      opacity: 1,
      // transform: "translate3d(0, 0, 0)",
      transition: {
          duration: 0.25,
          ease: [0.33, 1, 0.68, 1],
          transform: {
            duration: 0.25,
            ease: "easeOut"
          }
      }
  },
  exit: {
      opacity: 0,
      // transform: "translate3d(0, 100%, 0)",
      transition: {
          duration: 0.45,
          ease: [0.33, 1, 0.68, 1],
          transform:{
            duration: 0.45,
            ease: [0.33, 1, 0.68, 1]
          }
      }

  }
  }

  const cursorCharVariants = {
    initial: {
        transform: "translate3d(0px, 100%, 0px)",
        opacity: 0
    },
    animate: {
        transform: "translate3d(0px, 0%, 0px)",
        opacity: 1,
        transition: {
            duration: 0.25,
            ease: [0.33, 1, 0.68, 1]
        }
    },
    exit: {
        transform: "translate3d(0px, -100%, 0px)",
        opacity: 0,
        transition: {
            duration: 0.1,
            ease: [0.33, 1, 0.68, 1]
        }

    }
  }
  let prevCursorText;


  const positionRef = React.useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

    let mouseX;
    let mouseY;

  const mouseHandler = () => {}


  React.useEffect(() => {
    if(deviceType !== 'touchOnly' && primaryInput=='mouse'){
      document.addEventListener("mousemove", (event) => {
        const { clientX, clientY } = event;

        mouseX = clientX;
        mouseY = clientY;

        positionRef.current.mouseX =
          mouseX - secondaryCursor.current.clientWidth / 2;
        positionRef.current.mouseY =
          mouseY - secondaryCursor.current.clientHeight / 2;
      });
    }
    return () => {};
  }, []);

  React.useEffect(() => {
    
    if(deviceType !== 'touchOnly'){
      if(!cursorText){
        setIsCircle(true);
      }
      else{
        setIsCircle(false)
      }
    }
  }, [cursorText]
)

const mouseDown = (e) => {
  try{
    let custm_crsr = document.getElementById("cursor-circle");
    let test = document.getElementsByClassName("cursor-active-2");
    let test2 = document.getElementsByClassName("cursor-active");
    let prev;
    if (test.length || test2.length){
      prev = 0.75;
    } else{
      prev = 1
    }
    custm_crsr.style.transform = "scale(0.5)";
  } catch{

  }
}

const mouseUp = () => {
  try{
    let custm_crsr = document.getElementById("cursor-circle");
    let test = document.getElementsByClassName("cursor-active-2");
    let test2 = document.getElementsByClassName("cursor-active");
    let next;
    if (test.length || test2.length){
      next = 0.65;
    } else{
      next = 1
    }
    if(custm_crsr)
      setTimeout(() => {custm_crsr.style.transform = "";}, 200);
    
  } catch{

  }
}

  React.useEffect(() => {
    document.addEventListener("mousedown", (e) => mouseDown(e));
    document.addEventListener("mousedown", mouseUp);
    const followMouse = () => {
      if (!jsFlag){
        jsFlag = true;
        setHasMoved(true);
      }
      positionRef.current.key = requestAnimationFrame(followMouse);
      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current;
      if ((!destinationX || !destinationY)) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
        if (Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) < 0.001) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }
      try{
        secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
        prevCursorText = cursorText;
      }
      catch(err){
      }
    };
    if(deviceType !== 'touchOnly')
      followMouse();
  }, []);

  return (
    <>
    {
      deviceType !== 'touchOnly' &&
        <div className={`cursor-wrapper default`}>
          <AnimatePresence>
            <motion.div className="secondary-cursor" ref={secondaryCursor}
              key="cc-wrapper"
              initial={{opacity: 0}}
              animate={hasMoved ? {opacity: 1} : {opacity: 0}}
              transition={{
                duration: 0.8,
                delay: 0.5
              }}
            >
              {
                !isCircle ?
                  <motion.span
                    key="cursor-text-wrapper"
                    id="cursor-text"
                    variants={cursorTextVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    style={{transform: "translate(100%, -100%)"}}
                  >
                    ┐
                    { 
                      cursorText.split("").flat().map((char, idx) => {
                        return(
                            char == "_" ?
                              <motion.span>&nbsp;</motion.span>
                            :
                            <motion.span
                              key={"cursor-char"+idx}
                              style={{display: "inline-block"}}
                              variants={cursorCharVariants}
                              custom={idx}
                            >
                              {char}
                            </motion.span>
                              
                        )
                      })
                    
                    }
                  </motion.span>
                :
                  <motion.span
                    id="cursor-circle"
                    key="cursor-circle-wrapper"
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={cursorCircleVariants}
                  ></motion.span>
              }
            </motion.div>
          </AnimatePresence>
        </div>
    }
  </>
  );
};

export default CustomCursor;