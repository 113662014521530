export function easeOutElastic(x) {
    const c4 = (2 * Math.PI) / 3;
    
    return x == 0
      ? 0
      : x == 1
      ? 1
      : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
    }
  
export function easeOutCubic(x){
  return 1 - Math.pow(1 - x, 3);
}
export function easeInCubic(x) {
  return x * x * x;
  }
export function easeInOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function easeOutExpo(x) {
  return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2
}