import React from "react";
import {motion} from "framer-motion";

const menuOverlayTiming = 0.95;
const overlayVariants = {
    closing: {
        d: [
            "M0 1080V0H1920V1079.5C1920 1079.5 1917 1079.5 1024 1081C1 1080 0 1080 0 1080Z",
            "M0 582.5V0H1920V582.5C1920 582.5 1537.8 369 982.952 369C428.108 369 0 582.5 0 582.5Z",
            "M0 -6V0H1920.5V-8C1920.5 -8 1445 -12 977 -12C509 -12 0 -6 0 -6Z",
        ],
        transition: {
            duration: menuOverlayTiming,
            ease: [[0.32, 0, 0.67, 0], [0.33, 1, 0.68, 1]],
            times: [0, 0.5, 1],
            delay: 1
        },
        transitionEnd: {
            opacity: 0
        }
    },
    opening: {
        d: [
            "M0 -6V0H1920.5V-8C1920.5 -8 1445 -12 977 -12C509 -12 0 -6 0 -6Z",
            "M-1 582.351V0H1921V582.351C1921 582.351 1538.7 829 982.988 829C427.277 829 -1 582.351 -1 582.351Z", 
            "M0 1080V0H1920V1079.5C1920 1079.5 1917 1079.5 1024 1081C1 1080 0 1080 0 1080Z"
        ],
        transition: {
            duration: menuOverlayTiming,
            ease: [[0.32, 0, 0.67, 0], [0.33, 1, 0.68, 1]],
            times: [0, 0.5, 1],
            delay: 0
        }        
    },
    opened: {
        d: [
            "M0 1080V0H1920V1079.5C1920 1079.5 1917 1079.5 1024 1081C1 1080 0 1080 0 1080Z"
        ]
    }
}
const overlayVariants2 = {
    closing: i => ({
        d: [
            "M0 1080V0H1920V1079.5C1920 1079.5 1917 1079.5 1024 1081C1 1080 0 1080 0 1080Z",
            "M0 582.5V0H1920V582.5C1920 582.5 1537.8 369 982.952 369C428.108 369 0 582.5 0 582.5Z",
            "M0 -6V0H1920.5V-8C1920.5 -8 1445 -12 977 -12C509 -12 0 -6 0 -6Z",
        ],
        transition: {
            duration: menuOverlayTiming * 1.05,
            ease: [[0.32, 0, 0.67, 0], [0.33, 1, 0.68, 1]],
            times: [0, 0.5, 1],
            delay: 1
        },
        transitionEnd: {
            opacity: 0
        }
    }),
    opening: {
        d: [
            "M0 -6V0H1920.5V-8C1920.5 -8 1445 -12 977 -12C509 -12 0 -6 0 -6Z",
            "M-1 582.351V0H1921V582.351C1921 582.351 1538.7 829 982.988 829C427.277 829 -1 582.351 -1 582.351Z", 
            "M0 1080V0H1920V1079.5C1920 1079.5 1917 1079.5 1024 1081C1 1080 0 1080 0 1080Z"
        ],
        transition: {
            duration: menuOverlayTiming,
            ease: [[0.32, 0, 0.67, 0], [0.33, 1, 0.68, 1]],
            times: [0, 0.5, 1],
            delay: 0
        }
    },
    opened: {
        d: [
            "M0 1080V0H1920V1079.5C1920 1079.5 1917 1079.5 1024 1081C1 1080 0 1080 0 1080Z"
        ]
    }
}


const LoaderOverlay = ({isFirstLoad}) => {

    React.useEffect(() => {
    }, [isFirstLoad])

    return(
        <> 
            
            <motion.svg 
                key={"menu-overlay"+isFirstLoad}
                className="morph"
                initial="opened"
                viewBox="0 0 1920 1080"
                preserveAspectRatio='none'
                fill="none"
                exit="closing"
            >
                <clipPath 
                    id="loaderOverlay" 
                    clipPathUnits="objectBoundingBox"
                >
                    <motion.path 
                        id="mnu-overlay-path"
                        variants={overlayVariants}
                        fill="black"
                        stroke="black"
                    />
                </clipPath>
            </motion.svg>
            <motion.svg 
                key={"menu-overlay3-"+isFirstLoad}
                style={{
                    zIndex: 1000,
                    mixBlendMode: "difference"
                }}
                className="morph"
                initial="opened"
                viewBox="0 0 1920 1080"
                preserveAspectRatio='none'
                fill="white"
                exit="closing"
            >
                <motion.path
                    id="mnu-overlay-path2"
                    custom={2}
                    variants={overlayVariants2}
                    fill="white"
                    stroke="white"
                />
            </motion.svg>
        </>
    )
}

export default LoaderOverlay;