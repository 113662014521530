export const tabletBreak = 1180;
export const tableBreak = 860;
export const mobileBreak = 700;
export const large3 = [4, 11, 15]
export const largeItems = [2, 12, 16, 20]
export function getDevice(e=false){
    let width;
    let height;
    if (e){
      width = e.target.innerWidth;
      height = e.target.innerHeight;
    } else {
      width = window.innerWidth;
    }
    if(width > tabletBreak){
      return 'desktop'
    } else if(width > tableBreak && width <= tabletBreak ){
        return 'laptop'
    } else if (width > mobileBreak && width <= tableBreak){
        return 'tablet'
    } else if (width <= mobileBreak){
        return 'mobile'
    }
  }

export const sanitiser = (input) => {
  if (input)
    return input.replace(/[.,\/#!?$%\^&\*;:{}=\-_`\s~()]/g,"")
  return ""
}

export const cursorChange = (makeActive, activeType=0) => {
  let stringAppend = "";
  if (activeType==0)
    stringAppend = "-2"
  try{
      let cursor = document.getElementById("cursor-circle")
      if (makeActive){
          cursor.classList.add("cursor-active"+stringAppend)
      } else{
          cursor.classList.remove("cursor-active-2")
          cursor.classList.remove("cursor-active")
      }
  } catch{
      
  }
}

export function minuteRepresentation(seconds){
  let remainderSeconds = (Math.floor(seconds) % 60).toString();
  let minutes = Math.floor(seconds/60).toString();
  if(remainderSeconds.length < 2){
      remainderSeconds = '0' + remainderSeconds
  } else{
      if(minutes !=='0'){
          if(minutes.length < 2){
              minutes = '0' + minutes
          }
      }
  }

  return minutes+":"+remainderSeconds
}  

export function updateTime(setter, curDate){
  let dateEvent = new Date();
  let newDate = dateEvent.toLocaleString('en-GB', {hour: '2-digit', minute: '2-digit',  hour12: false, timeZone: 'Europe/London' });
  if (newDate !==curDate)
    setter(newDate)
}

export function calcCenterMove(element){

  /*
  X and Y are the current position of the element to be moved (top left corner).
  Width and Height are the width and height of the element to be moved.
  CX and CY are the X and Y coordinates of the centre of the screen.
  */

  var x       = element.getBoundingClientRect().x;
  var y       = element.getBoundingClientRect().y;
  var width   = element.getBoundingClientRect().width;
  var height  = element.getBoundingClientRect().height;
  var cx      = window.innerWidth / 2;
  var cy      = window.innerHeight / 2;
  var xVector = cx-(width/2)-x;
  var yVector = cy-(height/2)-y;
  return [xVector, yVector];
}