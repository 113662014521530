import React, {useState, useRef, useEffect} from "react";
import Vimeo from '@u-wave/react-vimeo';
import { AnimatePresence, motion } from "framer-motion";
import { getDevice } from "../../globals/helpers";
import { minuteRepresentation } from "../../globals/helpers";
import {playIconsVariants} from "../../globals/variants";

function Showreel({setCursorText}) {

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [viewDevice, setViewDevice] = useState(null);
    const [reactPlayer, setReactPlayer] = useState(null);
    const [duration, setDuration] = useState(null);
    const endTimeRef = useRef(null);
    const curTimeRef = useRef(null);
    const progressBar = useRef(null);
    const videoRef = useRef(null);

    function handleResize(){
        try{
            setViewDevice(getDevice());
        } catch(err){
            
        }
    }

    function handleTimerUpdate(e){
        if(duration==null){
            setDuration(e.duration)
            endTimeRef.current.innerHTML = minuteRepresentation(e.duration)
        }
        curTimeRef.current.innerHTML = minuteRepresentation(e.seconds)
        let percent = e.percent;
        progressBar.current.style.width = percent * 100 + "%"
    }

    function handleReady(player){
        setReactPlayer(player)
    }

    function handleOverlayClick(e){
        e.stopPropagation();
        e.preventDefault();
        if(isVideoPlaying){
            setCursorText("PLAY");
            videoRef.current.style.filter = 'grayscale(1)';
        }
        else{
            setCursorText("PAUSE");
            videoRef.current.style.filter = 'grayscale(0)';
        }
        setIsVideoPlaying(!isVideoPlaying);
    }

    function handleProgressClick(e){
        e.stopPropagation();
        let xRatio = (e.clientX - e.target.offsetLeft) / e.target.offsetWidth;
        let jumpTo = Math.floor(xRatio * duration);
        progressBar.current.style.width = xRatio * 100 + "%"
        reactPlayer.setCurrentTime(jumpTo)
    }

    useEffect(() => {

        return () => {
            setCursorText("")
        }
    }, [])
    return(
        <>
            <div className="showreel-page__wrapper">
                <motion.div
                    className="shoot-video"
                    initial="inital"
                    animate={isVideoPlaying ? "animate" : "exit"}
                    ref={videoRef}

                    onMouseEnter={() => {setCursorText(isVideoPlaying ? "PAUSE" : "PLAY")}}
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                >
                    <motion.div
                        style={{
                            height: "100%",
                            width: "100%",
                            position:"relative"
                        }}
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1,
                            transition:{
                                delay: 1
                            }
                        }}
                        exit={{
                            opacity: 0
                        }}
                    >
                        <div 
                            className="video-overlay" 
                            key="overlay-ref"
                            onClick={(e) => handleOverlayClick(e)}
                        />
                        <Vimeo
                            video="https://vimeo.com/842615941/73147d3dc7?share=copy"
                            className="vimeo__wrapper"
                            style={{
                                position:"absolute",
                                top:"0",
                                left:"0",
                                width:"100%",
                                height:"100%"
                            }}
                            width="100%"
                            height= "100%"
                            controls={false}
                            responsive
                            volume={isMuted ? 0 : 1}
                            paused={!isVideoPlaying}
                            currentTime={10}
                            onTimeUpdate={(e) => {handleTimerUpdate(e)}}
                            onReady={(player) => handleReady(player)}
                            
                        />
                    </motion.div>
                </motion.div>
                <motion.div
                    className={`playback-features`}
                    initial={{opacity: 0}}
                    animate={{
                        opacity: 1
                    }}
                    exit={{opacity: 0}}
                >
                    {
                        viewDevice !=='mobile' && viewDevice !=='tablet' &&
                            <motion.div
                                className="sound-option" 
                                key="vimeo-sound"
                                onClick={(e) => {
                                    setIsMuted(!isMuted);
                                    e.stopPropagation();
                                }}
                                onMouseEnter={() => {setCursorText("")}}
                                style={{
                                    //color: `${isMuted ? "var(--color-highlighted)" : "white"}`
                                }}
                            >
                                <motion.div
                                    className="sound-strikethrough" 
                                    style={{
                                        width: `${isMuted ? "100%" : "0%"}`,
                                        left: `${isMuted ? "0" : ""}`,
                                        right: `${isMuted ? "" : "0"}`,
                                    }}
                                />
                                <span>SOUND</span>
                            </motion.div>
                    }
                    <div 
                        className="playback-details padding-gutter"
                        onMouseEnter={() => {setCursorText("")}}
                    >
                        <div 
                            className="playback-title"
                            style={{fontFamily: "var(--font-punchy)", 
                                textTransform: "uppercase",
                                fontWeight: "300"
                            }}
                        >
                            <span>SHOWREEL '23</span>
                            <span 
                                className="playback-option-dial"
                                onMouseEnter={() => setCursorText("")}
                                onClick={(e) => handleOverlayClick(e)}
                            >
                                <AnimatePresence mode="wait">
                                {
                                !isVideoPlaying?
                                    <motion.svg width="9" height="10" viewBox="0 0 9 11" xmlns="http://www.w3.org/2000/svg" className="play-icon"
                                        key="play-icon"
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        variants={playIconsVariants}
                                    >
                                        <path d="M8.25 4.56699C8.58333 4.75944 8.58333 5.24056 8.25 5.43301L0.75 9.76314C0.416667 9.95559 4.85306e-07 9.71503 5.0213e-07 9.33013L8.80682e-07 0.669873C8.97506e-07 0.284973 0.416668 0.0444101 0.750001 0.23686L8.25 4.56699Z" fill="#EFEBE7"></path>
                                    </motion.svg>
                                :
                                    <motion.svg width="6" height="11" viewBox="0 0 6 11" xmlns="http://www.w3.org/2000/svg" className="pause-icon"
                                        key="pause-icon"
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        variants={playIconsVariants}
                                    >
                                        <path d="M0 0.333401C0 0.149268 0.149269 0 0.333402 0H1.66701C1.85114 0 2.00041 0.149269 2.00041 0.333402V9.66866C2.00041 9.85279 1.85114 10.0021 1.66701 10.0021H0.333402C0.149269 10.0021 0 9.85279 0 9.66866V0.333401Z" fill="#EFEBE7"></path>
                                        <path d="M4 0.333401C4 0.149268 4.14927 0 4.3334 0H5.66701C5.85114 0 6.00041 0.149269 6.00041 0.333402V9.66866C6.00041 9.85279 5.85114 10.0021 5.66701 10.0021H4.3334C4.14927 10.0021 4 9.85279 4 9.66866V0.333401Z" fill="#EFEBE7"></path>
                                    </motion.svg>
                                }
                                </AnimatePresence>
                            </span>
                        </div>
                        <div className="playback-progress">
                            <div
                                className="playback-bar__wrapper"
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    paddingTop: "4px",
                                    paddingBottom: "4px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    pointerEvents: "all"
                                }}
                                onMouseEnter={() => {setCursorText("")}}
                                onClick={(e) => {handleProgressClick(e)}}
                            >
                                <div className="playback-bar"
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        width: "100%",
                                        height: "2px",
                                        background: "grey",
                                        transition: "width 0.25s linear"
                                    }}></div>
                                <div
                                    className="progress"
                                    ref={progressBar}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        width: "0%",
                                        height: "2px",
                                        background: "white",
                                        transition: "width 0.25s linear"
                                    }}
                                />
                            </div>
                            <div className="playback-times">
                                <div ref={curTimeRef} className="cur-time">0:00</div>
                                <div ref={endTimeRef} className="end-time">{minuteRepresentation(duration)}</div>
                            </div>
                        </div>

                    </div>
                </motion.div>
            </div>
        </>
    )
}

export default Showreel;