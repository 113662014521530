
import React, {useState, useEffect} from "react";
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";


const placeholderVariants ={
    initial: {
      opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.45,
            ease: [0.33, 1, 0.68, 1]
        }
    }
}

const fullImgVariants ={
    initial: {
      opacity: 0,
      filter: 'blur(15px)',
      transform: 'scale(1.05)'
    },
    animate: {
        opacity: 1,
        filter: 'blur(0px)',
        transform: 'scale(1)',
        transition: {
            duration: 0.65,
            ease: [0.33, 1, 0.68, 1]
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.65,
            ease: [0.33, 1, 0.68, 1]
        }
    }
}

const ProgressiveBackgroundImg = ({ placeholderSrc, src, idx, unboundHeight = false, delay=0, forceWindowRescaling = false, forceFullWidth = false, isFirefox = false}) => {
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
    const [isNotDelayed, setIsNotDelayed] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '0px 0px'
      });
    const [isLoaded, setIsLoaded] = useState(false)
      
    useEffect(() => {
        setTimeout(() => {
            setIsNotDelayed(true);
        }, delay);
    }, [])

    function handleScaleChange(e){
        let imgAspectRatio = e.target.width / e.target.height;
        let windowAspectratio = window.innerWidth / window.innerHeight;
        if (imgAspectRatio > windowAspectratio){
            // Image wider than window relative to height, so height needs to be 100%
            e.target.classList.add("full-height");
        } else{
            //  Image taller than window or equal, so width needs to be 100% (happens on sufficiently wide screens)
            e.target.classList.add("full-width");
        }
    }

    return (
        <div ref={ref} className="grid-item__thumb">
            <motion.img 
                initial="intial"
                animate="animate"
                variants={placeholderVariants}
                style={{
                    position: 'absolute',
                    transform: 'scale(1.05)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    width: '100%',
                    height: '100%',
                    backgroundRepeat: 'no-repeat',
                    transition: '0.5s -webkit-filter linear,0.5s transform linear',
                    clipPath: isFirefox ? 'inset(1.5px)' : 'inset(0.8px)',
                    opacity: '1',
                    filter: 'none',
                    transform: 'none',
                    backgroundImage: 'url('.concat(placeholderSrc.toLowerCase(),')')
                }}
            />
            {
                inView && ( delay==0 || isNotDelayed) &&
                <motion.img
                    key={placeholderSrc+isLoaded+isNotDelayed}
                    className={"full-thumb isL-" + isLoaded + " isND-" + isNotDelayed}
                    initial="intial"
                    layoutId={"thumb"-idx}
                    animate={isLoaded && isNotDelayed ? "animate" : "initial"}
                    variants={fullImgVariants}
                    onLoad={(e) => {
                        if(forceWindowRescaling)
                            handleScaleChange(e)
                        setIsLoaded(true)
                    
                    }}
                    style={{
                        transform: delay==0 ? 'scale(1.05)' : "scale(1)",
                        position: 'absolute',
                        width: forceFullWidth ? "100%" : "",
                        height: forceWindowRescaling || forceFullWidth ? "" : '100%',
                        opacity: 0
                    }}
                    src={src.toLowerCase()}
                />
            }
        </div>

    );
  };
  export default ProgressiveBackgroundImg;