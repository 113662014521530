import React, {useEffect, useState, useRef} from 'react';
import { BrowserRouter as  Router, Routes, Route } from 'react-router-dom';

import './App.css';
import {primaryInput} from 'detect-it';
import { getDevice } from './globals/helpers';
import AnimatedRoutes from './AnimatedRoutes';
import NewMenu from './pages/NewMenu/NewMenu';
import Navbar from './components/Navbar/Navbar';
import { AnimatePresence } from 'framer-motion';
import CustomCursor from './components/CustomCursor/index'
import FirstLoader from './pages/FirstLoader/FirstLoader';
import LoaderOverlay from './components/LoaderOverlay/LoaderOverlay';

function App() {
  const [menuActive, setMenuActive] = useState(0);
  const [cursorText, setCursorText] = useState("");
  const [viewportWidth, setViewportWidth] = useState(document.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isOutdatedSafari, setIsOutdatedSafari] = useState(false);
  const [isHomeScreen, setIsHomeScreen] = useState(false);

  const supportedBrowsers = {
    'safari': 15.4,
    'chrome': 12.5,
    'firefox': 12.5
  }
  let fontScaler;
  let root = document.documentElement;
  let curWidth = window.innerWidth;
  let maxFontSize = 50;
  let newFontSize = Math.min(Math.round(curWidth * fontScaler), maxFontSize);
  let tabletBreak = 1180;
  let tableBreak = 860;
  let gridCols;
  var nVer;
  var nAgt;
  var browserName;
  var fullVersion;
  var majorVersion;
  var nameOffset,verOffset,ix;
  responsiveVariables(curWidth);

  const controlAuxCursor = (isCircle) => {
    if (isCircle){
      setCursorText("")
    } else{
      setCursorText("PLAY_SHOWREEL")
    }
  }

  const handleMenuClick = (route = null) => {
    if(menuActive==0){
        setMenuActive(1)
    }else{
        setMenuActive(menuActive * -1)
    }
    if(route){
        
    }
    let menuEle = document.getElementById('menu-btn__wrapper');
    // menuEle.style.pointerEvents = 'none';
    // setTimeout(() => {menuEle.style.pointerEvents = 'auto';}, 0.9*1000);
  }

  function responsiveResive(){
    let curPath = window.location.pathname;
    if (curPath=="/"){
      let homeGrid = document.getElementById("homepage-grid");
      let filmDetails = document.getElementsByClassName("film-detail");
      for(let i = 0; i<filmDetails.length; i++){
        let tmpFilmImg = filmDetails[i];
        tmpFilmImg.style.paddingTop = (44 / 2968) * homeGrid.offsetWidth + "px";
        tmpFilmImg.style.marginTop = (44 / (2968)) * homeGrid.offsetWidth + "px";
        tmpFilmImg.style.marginBottom = (44 / (2968)) * homeGrid.offsetWidth + "px";
      }
    }
    curWidth = window.innerWidth;
    responsiveVariables(curWidth)

    newFontSize = Math.max(10, Math.min(Math.round(curWidth * fontScaler), maxFontSize));
    root.style.setProperty('font-size', newFontSize+"px");
  }

  function responsiveVariables(tmpWidth){
    if(tabletBreak < tmpWidth){
      fontScaler = 1/63;
    }
    else if(tableBreak < tmpWidth  && tmpWidth< tabletBreak){
      fontScaler = 1/60;
    } else if(tmpWidth < tableBreak){
      fontScaler = 1/61;
    }
  }

  useEffect(() => {
    let location = window.location.pathname.split('/').slice(1).toString().toLocaleLowerCase();
    if (location != "index" && (primaryInput=="mouse" || (getDevice() !=="mobile" && getDevice() !=="tablet"))){
      document.documentElement.style.overflowY = "hidden";
    }
    responsiveResive();
    window.addEventListener('resize', responsiveResive, false);
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setTimeout(() => {
      if (location != "index" && (primaryInput=="mouse" || (getDevice() !=="mobile" && getDevice() !=="tablet"))){
        document.documentElement.style.overflowY = "hidden";
      }
      setIsFirstLoad(false)
      document.getElementsByTagName('body')[0].style.overflow = 'visible';
    }, 4250)
  }, [])

  function semverCompare(a, b) {
    if (a.startsWith(b + "-")) return -1
    if (b.startsWith(a + "-")) return  1
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: "case", caseFirst: "upper" })
}

  function getBrowserDetails(){
    nVer = navigator.appVersion;
    nAgt = navigator.userAgent;
    browserName  = navigator.appName;
    fullVersion  = ''+parseFloat(navigator.appVersion); 
    majorVersion = parseInt(navigator.appVersion,10);

    // In Opera, the true version is after "OPR" or after "Version"
    if ((verOffset=nAgt.indexOf("OPR"))!=-1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset+4);
    if ((verOffset=nAgt.indexOf("Version"))!=-1) 
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In MS Edge, the true version is after "Edg" in userAgent
    else if ((verOffset=nAgt.indexOf("Edg"))!=-1) {
    browserName = "Microsoft Edge";
    fullVersion = nAgt.substring(verOffset+4);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset+7);
    if ((verOffset=nAgt.indexOf("Version"))!=-1) 
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
              (verOffset=nAgt.lastIndexOf('/')) ) 
    {
    browserName = nAgt.substring(nameOffset,verOffset);
    fullVersion = nAgt.substring(verOffset+1);
    if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!=-1)
      fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!=-1)
      fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
    fullVersion  = ''+parseFloat(navigator.appVersion); 
    majorVersion = parseInt(navigator.appVersion,10);
    }


    console.log(''
    +'Browser name  = '+browserName+'<br>'
    +'Full version  = '+fullVersion+'<br>'
    +'Major version = '+majorVersion+'<br>'
    +'navigator.appName = '+navigator.appName+'<br>'
    +'navigator.userAgent = '+navigator.userAgent+'<br>'
    )

    let isSupported = semverCompare(fullVersion, String(supportedBrowsers[browserName.toLowerCase()]))
    if (isSupported==-1){
      return false
    } else{
      return true
    }
  }

  return (
    <>
      

      
        <div className="App">
          {
            getBrowserDetails() ?
              <Router>
                {
                  primaryInput=="mouse" &&
                  <CustomCursor cursorText={cursorText} menuActive={menuActive}/>
                }
                
                <AnimatePresence>
                  {
                    isFirstLoad && 
                    <>
                      <FirstLoader key={`isFirstLoad-`+isFirstLoad} isFirstLoad={isFirstLoad}/>
                      <LoaderOverlay key={`isFirstLoadOverlay-`+isFirstLoad} isFirstLoad={isFirstLoad}/>
                    </>
                  }
                </AnimatePresence>
                { !isFirstLoad &&
                  <Navbar 
                    menuActive={menuActive}
                    controlAuxCursor={controlAuxCursor}
                    handleMenuClick={handleMenuClick}
                    setCursorText={setCursorText}
                    isHomeScreen={isHomeScreen}  
                  />
                }
                <AnimatedRoutes 
                  controlAuxCursor={controlAuxCursor}
                  setCursorText={setCursorText}
                  setIsLoading={setIsLoading}
                  isOutdatedSafari={isOutdatedSafari}
                  setIsHomeScreen={setIsHomeScreen}
                />
                
              </Router>
              :
              <div 
                className='unsupported__wrapper'
                style={{
                  color: "white",
                  display: "flex",
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  width: '100vw',
                  position: 'absolute',
                  top: 0,
                  left: 0
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    fontFamily: 'var(--font-punchy)',
                    fontSize: "max(0.75rem, 11px)",
                  }}
                >
                  <span>MICHAEL R. JOHNSON</span>
                  <span>DIRECTOR OF PHOTOGRAPHY</span>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: "max(1rem, 10px)",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    color: "var(--color-highlighted)",
                    paddingBottom: '10vh',
                    textAlign: 'center'
                  }}
                >
                  <span>You are utilising an un-supported browser to access this website.</span>
                  <span>&nbsp;</span>
                  { 
                    Object.keys(supportedBrowsers).includes(browserName.toLowerCase()) ?
                        <>
                        <span >{"You are currently using " + browserName + ", version: " + fullVersion +"."} </span>
                        <span >{"The oldest version supported by this website is: " + String(supportedBrowsers[browserName.toLowerCase()])}</span>
                        <span>&nbsp;</span>
                        <span style={{paddingBottom: "0.25rem"}}>Please update your browser, or use one of the browsers found below</span>
                        </>
                    :
                        <>
                        <span >{"You are currently using " + browserName + ", version: " + fullVersion +"."} </span>
                        <span>{"This browser is not supported at the moment, please see below for alternate browser choices."}</span>
                        <span>&nbsp;</span>
                        </>
    
                  }
                  

                  <span>The latest versions of <span style={{color: 'white'}}>Safari</span>, <span style={{color: 'white'}}>Firefox</span> and <span style={{color: 'white'}}>Chrome</span> are currently supported.</span>
                </div>
              </div> 
          }
        </div>
    </>
  );
}

export default App;
